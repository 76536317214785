import React, { Component } from "react";
import ReactModal from "react-modal";
// import EditDeleteModal from './EditDeleteModal';
import RescheduleModal from "./RescheduleModal";
import JetsweatModal from "../../components/JetsweatModal";
import Workout from "./Workout";
import "./day.css";

const moment = require("moment");

const customStyles = {
  content: {
    borderRadius: "5px",
    boxShadow: "9px 11px 10px -5px rgba(0,0,0,0.51)",
    minWidth: "500px",
  },
};
//This solves the following thrown in tests:
//Error: react-modal: No elements were found for selector #root
if (process.env.NODE_ENV !== "test") ReactModal.setAppElement("#root");

class DayContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      wktReschedule: {},
      deleteModal: false,
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal(wkt, deleteFlag = false) {
    this.setState({
      modalIsOpen: true,
      wktReschedule: wkt,
      deleteModal: deleteFlag,
    });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  render() {
    const { whiteLabelBackgroundStyles } = this.props;
    return (
      <div
        className="day-wrapper"
        style={
          whiteLabelBackgroundStyles.backgroundColor
            ? { borderColor: whiteLabelBackgroundStyles.backgroundColor }
            : {}
        }
      >
        <JetsweatModal
          {...this.props}
          showModal={this.state.modalIsOpen}
          closeModal={this.closeModal}
          title={
            this.state.deleteModal
              ? "Are you sure you want to delete?"
              : "Edit Workout"
          }
          subTitle={
            this.state.wktReschedule.liveClass
              ? this.state.wktReschedule.liveClass
              : this.state.wktReschedule.video
          }
        >
          <RescheduleModal
            {...this.props}
            calendarDefaultDate={this.props.calendarDefaultDate}
            setCalendarDefaultDate={this.props.setCalendarDefaultDate}
            workout={this.state.wktReschedule}
            user={this.props.user}
            onScheduleChange={this.props.onScheduleChange}
            closeModal={this.closeModal}
            deleteModal={this.state.deleteModal}
            date={this.props.date}
          />
        </JetsweatModal>
        <div>
          <div className="day-circle" style={whiteLabelBackgroundStyles} />
          <div className="workout-date">
            {moment(this.props.date, "MM-DD-YYYY").format("MM-DD-YYYY")}
          </div>
        </div>
        <div className="workouts-wrapper">
          {this.props.workouts.map((wkt, idx) => (
            <Workout
              {...this.props}
              wkt={wkt}
              key={idx}
              openModal={this.openModal}
              mapProgIdToTitle={this.props.mapProgIdToTitle}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default DayContainer;
