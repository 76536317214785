import { ActionTypes } from '../../actions/stripe.actions'

const initialState = {
  stripe_publishable_key: null,
  stripeApiKey: null,
  pauseSubscriptionSuccess: null,
}

const StripeReducer = (state = initialState, action) => {
  switch (action.type) {
    // case ActionTypes.GET_STRIPE_API_KEY:
    //   return { ...state, success: null }
    case ActionTypes.GET_STRIPE_API_KEY_SUCCESS:
      return { ...state, success: null, stripeApiKey: action.stripeApiKey }
    case ActionTypes.GET_STRIPE_API_KEY_FAILURE:
      return { ...state, success: null }
    case ActionTypes.CANCEL_USER_STRIPE_SUBCRIPTION:
      return { ...state, success: true  }
    case ActionTypes.CANCEL_USER_STRIPE_SUBCRIPTION_SUCCESS:
      return { ...state, success: true  }
    case ActionTypes.CANCEL_USER_STRIPE_SUBCRIPTION_FAILURE:
      return { ...state, success: false  }
    case ActionTypes.PAUSE_USER_STRIPE_SUBSCRIPTION:
      return { ...state, pauseSubscriptionSuccess: null }
    case ActionTypes.PAUSE_USER_STRIPE_SUBSCRIPTION_SUCCESS:
      return { ...state, pauseSubscriptionSuccess: true }
    case ActionTypes.PAUSE_USER_STRIPE_SUBSCRIPTION_FAILURE:
      return { ...state, pauseSubscriptionSuccess: false }
    case ActionTypes.UNDO_PAUSE_USER_STRIPE_SUBSCRIPTION:
      return { ...state, success: true }
    case ActionTypes.UNDO_PAUSE_USER_STRIPE_SUBSCRIPTION_SUCCESS:
      return { ...state, success: true }
    case ActionTypes.UNDO_PAUSE_USER_STRIPE_SUBSCRIPTION_FAILURE:
      return { ...state, success: false }
    case ActionTypes.UNDO_CANCEL_USER_STRIPE_SUBCRIPTION:
      return { ...state, success: true }
    case ActionTypes.UNDO_CANCEL_USER_STRIPE_SUBCRIPTION_SUCCESS:
      return { ...state, success: true }
    case ActionTypes.UNDO_CANCEL_USER_STRIPE_SUBCRIPTION_FAILURE:
      return { ...state, success: false }
    default:
      return state
  }
}

export default StripeReducer
