import {
  getDailyWorkout,
  getWeeklyData,
  getWeeklyStatus,
  fetchRecommendedPrograms,
  fetchClasses,
  fetchHistoryClasses,
  fetchFavoriteClasses,
  fetchNoEquipmentClasses,
  fetchClassTypes,
  fetchFavoriteStudios,
  fetchAllStudios,
} from "../../actions"
// import updateWorkoutStatus from '../Calendar/CalendarUtil';
// import { calculateUserAge } from '../../utilities';
// import axios from 'axios';

const initialState = {
  videoData: {},
  totals: {},
  status: {},
  workoutDates: [],
  workoutStatuses: [],
  classTypes: [],
  allStudios: [],
  favoriteStudios: []
}

export const fetchProfileData = function (userData) {
  return async (dispatch) => {
    const response = await fetch("/api/workout/dailyWorkout", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: userData.authToken,
      },
    })
    const data = await response.json()

    const videoData = data.content

    const action = getDailyWorkout(videoData)
    dispatch(action)
  }
}

export const getClassTypes = (authToken) => async (dispatch) => {
  try {
    const res = await fetch("/api/basic/classTypes", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: authToken,
      },
    })

    const data = await res.json()
    // action
    const action = fetchClassTypes(data.content)
    // dispatch
    dispatch(action)
  } catch (err) {
    console.log(err)
  }
}

export const getStudios = (authToken) => async (dispatch) => {
  try {
    const res = await fetch("/api/basic/studios", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: authToken,
      },
    })

    const data = await res.json()

    const action = fetchAllStudios(data.content)

    dispatch(action)

  } catch (error) {
    console.log(error)
  }
}

export const fetchDailyWorkout = (authToken) => async (dispatch) => {
  try {
    const res = await fetch("/api/workout/dailyWorkout", {
      method: "post",
      headers: {
        "Content-type": "application/json",
        Authorization: authToken,
      },
    })
    const data = await res.json()
    // ACTION
    const action = getDailyWorkout(data.content[0])
    // DISPATCH ACTION

    dispatch(action)
  } catch (error) {
    console.log(error)
  }
}

export const getRecommendedPrograms = (authToken) => async (dispatch) => {
  try {
    let programs = await fetch("/api/programs/list", {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: authToken,
      },
    })
    programs = await programs.json()
    const recommendedPrograms = programs.content.recommendedPrograms
    // ACTION
    const action = fetchRecommendedPrograms(recommendedPrograms)
    // DISPATCH ACTION
    dispatch(action)
  } catch (error) {
    console.log(error)
  }
}

export const getFavoriteStudios = (authToken) => async (dispatch) => {
  try {
    const res = await fetch("/api/favoriteStudios/myFavoriteStudios", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: authToken,
      },
    })

    const data = await res.json()
    // Action
    const action = fetchFavoriteStudios(data.content)
    // Dispatch action
    dispatch(action)

  } catch (error) {
    console.log(error)
  }

}


export const getFavorites = (authToken) => async (dispatch) => {
  try {
    let classes = await fetch("/api/favorites/myFavorites", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: authToken,
      },
    })
    classes = await classes.json()
    classes = classes.content
    // ACTION
    const action = fetchFavoriteClasses(classes)
    // DISPATCH ACTION
    dispatch(action)
  } catch (error) {
    console.log(error)
  }
}

export const getHistory = (authToken) => async (dispatch) => {
  try {
    let classes = await fetch("/api/history/myHistory", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: authToken,
      },
    })
    classes = await classes.json()
    classes = classes.content
    // ACTION
    const action = fetchHistoryClasses(classes)
    // DISPATCH ACTION
    dispatch(action)
  } catch (error) {
    console.log(error)
  }
}

export const getNoEquipmentClasses = (authToken) => async (dispatch) => {
  try {
    let classes = await fetch("/api/video/videos", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: authToken,
      },
      body: JSON.stringify({
        targetMucles: 0,
        classType: {},
        studio: {},
        equipment: ["none"],
        timeLength: {
          min: 0,
          max: 90,
        },
        calorieBurn: {
          min: 0,
          max: 800,
        },
        level: 0,
        bodyFocus: 0,
        limitations: 0,
        sort: {
          newest: false,
          longest: false,
          recommended: true,
          shortest: false,
          highest: false,
        },
      }),
    })
    classes = await classes.json()
    classes = classes.content
    const action = fetchNoEquipmentClasses(classes)
    dispatch(action)
  } catch (error) {
    console.log(error)
  }
}

export const getClasses = (authToken) => async (dispatch) => {
  try {
    let classes = await fetch("/api/video/videos", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: authToken,
      },
      body: JSON.stringify({
        targetMucles: 0,
        classType: {},
        studio: {},
        equipment: {},
        timeLength: {
          min: 0,
          max: 90,
        },
        calorieBurn: {
          min: 0,
          max: 800,
        },
        level: 0,
        bodyFocus: 0,
        limitations: 0,
        sort: {
          newest: false,
          longest: false,
          recommended: true,
          shortest: false,
          highest: false,
        },
      }),
    })
    classes = await classes.json()
    classes = classes.content
    const action = fetchClasses(classes)
    dispatch(action)
  } catch (error) {
    console.log(error)
  }
}

export const fetchThisWeeksData = function (userData, startWeek, endWeek) {
  // const start = startWeek.format("YYYY-MM-DD HH:mm")
  // const end = endWeek.format("YYYY-MM-DD HH:mm")
  return async (dispatch) => {
    const response = await fetch("/api/history/myprogress", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: userData.authToken,
      },
      body: JSON.stringify({
        fromDate: `${startWeek}`,
        toDate: `${endWeek}`,
        beforeDate: `${endWeek}`,
      }),
    })
    const json = await response.json()

    const progress = json.content
    const weeklyTotals = progress.total
    const action = getWeeklyData(weeklyTotals)
    dispatch(action)
  }
}

export const fetchThisWeeksStatus = function (userData, startWeek, endWeek) {
  return async (dispatch) => {
    const response = await fetch("/api/workout/myWorkout", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: userData.authToken,
      },
      body: JSON.stringify({
        fromDate: startWeek,
        toDate: endWeek,
      }),
    })
    const json = await response.json()
    //debugger
    const workoutDates = json.content.workouts.map((ele) => ele.when)
    const workoutStatuses = json.content.workouts.map(
      (ele) => ele.status || "scheduled"
    )
    const { status } = json.content

    const action = getWeeklyStatus({ workoutDates, workoutStatuses, status })
    dispatch(action)
  }
}

export default function profileReducer(state = initialState, action) {
  switch (action.type) {
    case "GET_CLASSES":
      return {
        ...state,
        classes: action.classes,
      }
    case "GET_FAVORITE_CLASSES":
      return {
        ...state,
        favoriteClasses: action.classes,
      }
    case "GET_HISTORY_CLASSES":
      return {
        ...state,
        historyClasses: action.classes,
      }
    case "GET_NO_EQUIPMENT_CLASSES":
      return {
        ...state,
        noEquipmentClasses: action.classes,
      }
    case "GET_RECOMMENDED_PROGRAMS":
      return {
        ...state,
        recommendedPrograms: action.recommendedPrograms,
      }
    case "GET_DAILY_WORKOUT":
      return {
        ...state,
        dailyWorkout: action.dailyWorkout,
      }
    case "GET_WEEKLY_DATA":
      return {
        ...state,
        totals: action.totals,
      }
    case "GET_WEEKLY_STATUS":
      return {
        ...state,
        status: action.week.status,
        workoutDates: action.week.workoutDates,
        workoutStatuses: action.week.workoutStatuses,
      }
    case "GET_ALL_CLASSTYPES":
      return {
        ...state,
        classTypes: action.classes,
      }
    case "GET_ALL_STUDIOS":
      return {
        ...state,
        allStudios: action.classes,
      }
    case "GET_FAVORITE_STUDIOS":
      return {
        ...state,
        favoriteStudios: action.studios,
      }
    default:
      return state
  }
}
