import React, { Component } from 'react';
import './signup.css';
import Footer from '../footer.js';

class ForgotPassword extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      message: '',
      error: '',
    };
  }

  handleChange = (event) => {
    const fieldName = event.target.name;
    this.setState({ [fieldName]: event.target.value });
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    let filter = ""
    let { whiteLabelDomainStudio } = this.props
    if (whiteLabelDomainStudio) {
      filter = `?studioId=${whiteLabelDomainStudio.id}`
    }
    try {
      const data = await fetch(`/api/user/reset-password${filter}`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify({
          email: this.state.email
        })
      });
      // if no error, redirect, otherwise tell them that email is not valid
      if (data.status === 404) {
        this.setState({ message: 'This user does not exist' })
      }
      if (data.status === 200) {
        this.setState({ message: 'Password reset successful! Please check your email to get your new password and login!' })
        // this.props.history.push('/login');
      } else {
        this.setState({ error: data.error });
      }
    } catch (e) {
      console.log(e);
      this.setState({ error: e.content });
    }
  }

  render() {

    const { message, error } = this.state;
    const { whiteLabelFontStyles, whiteLabelBackgroundStyles } = this.props;

    return (
      <div className='reset-password'>
        <form onSubmit={this.handleSubmit}>
          <div className="Background-color-nav" />

          <p style={whiteLabelFontStyles} className="Main-text Padding"> Reset Password </p>
          {
            message ?
              <div className="errormessage">
                <div className="Center Padding">{message}</div>
              </div>
              : ''
          }
          {
            error ?
              <div className="errormessage">
                <div className="Center Padding">{error}</div>
              </div>
              : ''
          }

          <div className="Center Padding">
            <input
              id="signupemail"
              autoFocus
              type="text"
              name="email"
              onChange={this.handleChange}
              className="Input-style Font No-highlight-border"
              placeholder="Email"
            />
          </div>
          <div className="Center Padding">
            <button style={whiteLabelBackgroundStyles} type="submit" className="Button-style Font Login-button">
              {' '}
              RESET PASSWORD
              {' '}
            </button>
            <br />
          </div>
        </form>
        <div className="footer-login-original">
          <Footer studio={this.props.studio} />
        </div>
      </div>
    );
  }
}

export default ForgotPassword;