import React, { Component, lazy, Suspense } from "react";
import { connect } from "react-redux";
import branch from "branch-sdk";
import { withRouter, Switch, Route } from "react-router-dom";
import axios from "axios";
import ReactPixel from "react-facebook-pixel";
import "./App.scss";
import "aos/dist/aos.css";
import "./views/New_Platform/Dashboard/DashboardBody.scss";
import "./views/New_Platform/Progress/Progress.scss";
import { BeatLoader as Loader } from "react-spinners";
import AuthenticatedRoute from "components/AuthenticatedRoute";
import authFetch from "authFetch/authFetch";
import AOS from "aos";
import { Helmet } from "react-helmet";
import jetsweatFavicon from "images/favicon.ico";
import { compose } from "redux";
import Home from "screens/home/home.screen";
import sailsIOClient from "sails.io.js";
import socketIOClient from "socket.io-client";
import SingleStudioContainer from "./components/studios/SingleStudioView";
import LegacyNavbar from "./components/navbar.js";
import Navbar from "./screens/home/sections/navbar/navbar.js";
import CacheBuster from "./CacheBuster";
// import Jumbotron from './components/jumbotron.js'
import PromoSignup from "./components/account/promoSignup.js";
import NewPromoSignup from "./components/account/NewPromoSignup.js";
import GymPassSignup from "./components/gympass/GymPassSignup.js";
import WhiteLabelDaypass from "./components/whitelabel/WhiteLabelDaypass";
import Healthcare from "./components/HealthCare.js";
import Payment from "./components/payment.js";
import Subscription from "./components/subscription.js";
import Cancel from "./components/cancel.js";
import LiveSchedule from "./components/liveschedule/LiveSchedule";
import { Redirect } from "react-router";
import ForgotPassword from "./components/account/ForgotPassword";
import Contact from "./components/contact.js";
import Account from "./components/account/account.js";
import Logout from "./components/account/logout.js";
import PrivacyPolicy from "./components/privacypolicy.js";
import Terms from "./components/terms.js";
import Howtoconnect from "./components/howtoconnect";
import ExploreStudio from "./components/sliders/exploreStudio";
import CalendarContainer from "./views/Calendar/CalendarContainer";
import AppleCertificate from "./apple-certificate";
import FAQ from "./components/faq.js";
import HubSpot from "./utilities/hubSpot";
import UnknownPage from "./components/UnknownPage";
import Sandbox from "./sandbox";
import { setupLogging } from "./logger.js";
import {
  graphClient,
  settingAuth,
  unsynced,
  syncing,
  synced,
  clearSignOut,
  clearSignIn,
} from "./views/store/calendarReducer";
// import WorkplaceWellness from "./components/WorkplaceWellness.js";
import WorkplaceWellness from "../src/views/WellnessV2/CorporateWellness"

import Education from "./components/Education.js";
import "screens/home/text.css";
import { WhiteLabelCheckoutConfirmation } from "./components/whitelabel/WhiteLabelCheckoutConfirmation";
import packageJson from "../package.json";
import toBoolean from "validator/es/lib/toBoolean";

export let USER = {};

const moment = require("moment");

const defaultLogo = require("./images/blacklogo.png");

const PlatformContainer = lazy(() =>
  import("./views/New_Platform/PlatformContainer")
);
const AllStudios = lazy(() => import("./components/studios/allStudios"));

const WhiteLabelPayment = lazy(() =>
  import("./components/whitelabel/WhiteLabelPayment.js")
);
const WhiteLabelPaymentV2 = lazy(() =>
  import("./components/whitelabel/WhiteLabelPaymentV2.js")
);
const WhiteLabelPaymentV3 = lazy(() =>
  import("./components/whitelabel/WhiteLabelPaymentV3.js")
);
const WhiteLabelSignup = lazy(() =>
  import("./components/whitelabel/WhiteLabelSignUp.js")
);
const WhiteLabelLogin = lazy(() =>
  import("./components/whitelabel/WhiteLabelLogin.js")
);
const Signup = lazy(() => import("./components/account/signup.js"));
const Login = lazy(() => import("./components/account/login.js"));

const Howitworks = lazy(() => import("./components/howitworks.js"));
const Programs = lazy(() => import("./components/programs/Programs"));
const Compatibledevices = lazy(() =>
  import("./components/compatibledevices.js")
);
const JetsweatLive = lazy(() => import("./components/jetsweatLive.js"));

window.isProduction = !!(
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
);

class App extends Component {
  constructor(props) {
    super(props);
    const io = sailsIOClient(socketIOClient);
    const URL = "https://api.two-way.jetsweatfitness.com:1337";
    //const URL = "http://localhost:1337"
    io.sails.url = URL;
    // const socket = io.sails.connect(URL);
    this.state = {
      isLoggedIn: !!localStorage.authToken,
      isLoading: true,
      subscription: "monthly",
      currUser: null,
      whiteLabelURLSuffix: "",
      whiteLabelStudios: [],
      navBarLogo: "",
      whiteLabel: false, // studios.some((studio) =>window.location.pathname.includes(studio.link)),
      whiteLabelDomainStudio: {},
      whiteLabelDomain: null,
      whiteLabelBackgroundStyles: {},
      whiteLabelFontStyles: {},
      pageTitle:
        "JETSWEAT | Leading Fitness Studio Streaming 24/7 Workout Classes",
      faviconSrc: jetsweatFavicon,
      io,
    };

    this.setCurrUser = this.setCurrUser.bind(this);
    this.setLogo = this.setLogo.bind(this);
    this.setAuthToken = this.setAuthToken.bind(this);
    this.loginFromToken = this.loginFromToken.bind(this);
    this.logout = this.logout.bind(this);
    this.setSubscription = this.setSubscription.bind(this);
    this.getAuthToken = this.getAuthToken.bind(this);
    this.refreshLoggedInUser = this.refreshLoggedInUser.bind(this);
    this.initiateExternalServices();
    this.redirectToHomePage = this.redirectToHomePage.bind(this);
  }

  setSubscription(subscription) {
    this.setState({ subscription });
  }

  setAuthToken(authToken) {
    localStorage.setItem("authToken", authToken);
  }

  setLogo(studio = defaultLogo) {
    this.setState({ navBarLogo: studio });
  }

  setCurrUser(currUser) {
    localStorage.setItem("currUser", JSON.stringify(currUser));
    if (currUser.authToken) {
      this.setAuthToken(currUser.authToken);
    }
    USER = currUser;
    this.setState(
      {
        isLoggedIn: true,
        isLoading: false,
        currUser,
      },
      () => {}
    );
  }

  logout() {
    localStorage.removeItem("currUser");
    localStorage.removeItem("randomDaily");
    localStorage.removeItem("authToken");
    this.setState({
      isLoggedIn: false,
      isLoading: false,
      currUser: null,
    });
  }

  getAuthToken() {
    return this.state.currUser.authToken;
  }

  initiateExternalServices() {
    branch.init("key_live_birB93AdBoSzWQwgtkp9vmdlryn2vOgO");
  }

  loginFromToken(authToken) {
    // Fetch user and subscription
    if (!authToken) {
      return Promise.reject();
    }

    let user = null;

    return authFetch("api/user/me", { headers: { Authorization: authToken } })
      .then((body) => {
        if (!body.status) {
          return Promise.reject(body.content);
        }
        user = body.content;
        user.doneLoading = false;
        user.authToken = authToken;
        return { statues: true, content: user };
      })
      .then(() =>
        authFetch("api/user/subscription", {
          headers: { Authorization: authToken },
        })
      )
      .then((body) => {
        if (!body.status) {
          return Promise.reject(body.content);
        }
        user.doneLoading = true;
        user.subscription = body.content;
        this.loginUserSuccess(authToken, user);

        return { statues: true, content: user };
      })
      .catch((error) => {
        this.logout();
        console.error("Failed to fetch user information");
      });
  }

  loginUserSuccess(authToken, user) {
    this.setCurrUser({ ...user, authToken });
  }

  getToken() {
    return localStorage.getItem("authToken") || "";
  }

  refreshLoggedInUser() {
    const authToken = this.getToken();

    if (authToken) {
      return this.loginFromToken(authToken);
    }
    return Promise.resolve(null);
  }

  componentDidUpdate(prevProps, prevState) {
    HubSpot(window.location.href.includes("/newplatform"));
    const { currUser, whiteLabelDomainStudio } = this.state;
    if (
      (!prevState.currUser && currUser) ||
      (!prevState.currUser && currUser && whiteLabelDomainStudio.name)
    ) {
      this.googleCalendarInterval();
      this.props.settingAuth(
        "GoogleAuth",
        currUser,
        whiteLabelDomainStudio.whitelabelDomain
      );

      this.outlookCalendarInterval();
      this.props.settingAuth(
        "OutlookAuth",
        currUser,
        whiteLabelDomainStudio.whitelabelDomain
      );
    }
  }

  getStudio(studioLink, data) {
    return data.filter(({ shortName }) => shortName === studioLink)[0];
  }

  googleCalendarInterval = () => {
    setInterval(() => {
      const {
        GoogleAuth,
        syncing,
        synced,
        unsynced,
        clearSignOut,
        clearSignIn,
      } = this.props;
      const syncQueue =
        JSON.parse(localStorage.getItem("syncQueueGoogle")) || [];
      const signingOut = localStorage.getItem("signingOut");
      const signingIn = localStorage.getItem("signingIn");
      const { currUser } = this.state;
      if (GoogleAuth && GoogleAuth.isSignedIn.get() && syncQueue.length) {
        const { resource, calendarId, eventId, method } = syncQueue.shift();
        clearSignIn();
        syncing("GoogleCalendar");
        switch (method) {
          case "POST":
            window.gapi.client.calendar.events
              .insert({
                calendarId,
                resource,
              })
              .execute();
            break;
          case "DELETE":
            window.gapi.client.calendar.events
              .delete({
                calendarId,
                eventId,
              })
              .execute();
          case "PUT":
            window.gapi.client.calendar.events
              .patch({
                calendarId,
                eventId,
                resource,
              })
              .execute();
            break;
        }
      } else if (
        GoogleAuth &&
        GoogleAuth.isSignedIn.get() &&
        signingOut === "GoogleAuth" &&
        !signingIn &&
        !syncQueue.length
      ) {
        unsynced("GoogleCalendar");
        GoogleAuth.signOut();
        clearSignOut();
      } else if (
        GoogleAuth &&
        GoogleAuth.isSignedIn.get() &&
        !signingOut &&
        signingIn === "GoogleAuth" &&
        !syncQueue.length
      ) {
        clearSignIn();
        unsynced("GoogleCalendar");
      } else if (
        GoogleAuth &&
        GoogleAuth.isSignedIn.get() &&
        !signingOut &&
        !signingIn &&
        !syncQueue.length
      ) {
        synced("GoogleCalendar");
      }
      localStorage.setItem("syncQueueGoogle", JSON.stringify(syncQueue));
    }, 1000);
  };

  outlookCalendarInterval = () => {
    setInterval(() => {
      const {
        OutlookAuth,
        syncing,
        synced,
        unsynced,
        clearSignOut,
        clearSignIn,
      } = this.props;
      const syncQueue =
        JSON.parse(localStorage.getItem("syncQueueOutlook")) || [];
      const graphUser = JSON.parse(sessionStorage.getItem("graphUser"));
      const signingOut = localStorage.getItem("signingOut");
      const signingIn = localStorage.getItem("signingIn");
      const { currUser } = this.state;
      if (graphUser && syncQueue.length) {
        const { event, eventId, method } = syncQueue.shift();
        clearSignIn();
        syncing("Outlook");

        switch (method) {
          case "POST":
            (async function () {
              await graphClient.api("/me/events").post(event);
            })();
            break;
          case "DELETE":
            (async function () {
              await graphClient.api(`/me/events/${eventId}`).delete();
            })();
            break;
          case "PUT":
            (async function () {
              await graphClient.api(`/me/events/${eventId}`).update(event);
            })();

            break;
        }
      } else if (
        graphUser &&
        signingOut === "OutlookAuth" &&
        !signingIn &&
        !syncQueue.length
      ) {
        unsynced("Outlook");
        // OutlookAuth.logout()
        sessionStorage.removeItem("graphUser");
        clearSignOut();
      } else if (
        graphUser &&
        !signingOut &&
        signingIn === "OutlookAuth" &&
        !syncQueue.length
      ) {
        clearSignIn();
        unsynced("Outlook");
      } else if (graphUser && !signingOut && !signingIn && !syncQueue.length) {
        synced("Outlook");
      }
      localStorage.setItem("syncQueueOutlook", JSON.stringify(syncQueue));
    }, 1000);
  };

  async componentDidMount() {
    HubSpot(window.location.href.includes("/newplatform"));
    AOS.init({
      duration: 1000,
    });
    const { data } = await axios.get("/api/studio/listWhitelabels", {
      headers: {
        accepts: "application/json",
      },
    });

    const whiteLabelStudios = data.content;

    let studio = {};

    const results = whiteLabelStudios.filter((studio) =>
      window.location.pathname.includes(studio.shortName)
    );
    if (results.length > 0) {
      studio = results[0];
    }

    this.setState({
      whiteLabelStudios,
      whiteLabelDomainStudio: studio,
    });

    const envWhiteLabelDomain =
      process?.env?.REACT_APP_WHITE_LABEL_DOMAIN ?? "";
    let currentHost = (window?.location?.host ?? "").replace("www.", "");
    if (envWhiteLabelDomain.length > 0) {
      currentHost = envWhiteLabelDomain;
    }

    const whiteLabelDomainList = whiteLabelStudios.filter(
      (studio) => {
        const isWhiteLabelLogin = (window?.location?.pathname ?? "").includes(`/login/${studio?.shortName}`)
        return studio.whitelabelDomain === currentHost || isWhiteLabelLogin
      }
    );

    if (whiteLabelDomainList.length > 0) {
      let whiteLabelDomain = whiteLabelDomainList[0];
      let pageTitle = whiteLabelDomain.pageTitle;
      let faviconSrc = whiteLabelDomain.faviconUrl;

      const studio = this.getStudio(
        whiteLabelDomain.shortName,
        whiteLabelStudios
      );

      const { colorCode } = studio;
      this.setState({
        whiteLabel: true,
        whiteLabelDomainStudio: studio,
        navBarLogo: studio.logoUrl,
        whiteLabelDomain: true,
        whiteLabelBackgroundStyles: {
          backgroundColor: `#${colorCode}`,
        },
        whiteLabelFontStyles: {
          color: `#${colorCode}`,
        },
        pageTitle,
        faviconSrc,
      });
    } else {
      this.setState({
        whiteLabelDomain: false,
      });
    }
    const authToken = this.getToken();
    if (authToken) {
      setupLogging(authToken);

      // Then do a proper login from the token
      return this.loginFromToken(authToken).catch(() =>
        this.setState({
          isLoading: false,
        })
      );
    }

    this.setState({
      isLoading: false,
      whiteLabelStudios,
    });
  }

  redirectToHomePage() {
    const { pathname } = this.props.location;
    const studioName = pathname.replace("/studios/", "");
    const removeStudios = [
      "skytingyoga",
      "chloekernaghanyoga",
      "trooperfitness",
    ];

    if (removeStudios.includes(studioName)) return this.props.history.push("/");
  }

  getWhiteLabelDomainRoutes() {
    const {
      whiteLabelDomain,
      whiteLabelDomainStudio,
      whiteLabelBackgroundStyles,
      whiteLabelFontStyles,
      pageTitle,
      faviconSrc,
      navBarLogo,
    } = this.state;

    if (!whiteLabelDomainStudio) return null;
    const { isLoading, currUser } = this.state;
    const AuthenticatedRouteCurry = (props) => (
      <AuthenticatedRoute
        {...props}
        isWhiteLabelDomain={this.state.whiteLabelDomain}
        isLoading={isLoading}
        currUser={currUser}
      />
    );
    // const reload = () => window.location.reload()
    return (
      <div>
        <Helmet>
          <title>{pageTitle}</title>
          <link rel="shortcut icon" href={faviconSrc} />
          <meta
            name="description"
            content="Stream The Top Fitness Classes Anytime, Anywhere. Yoga, Cardio, HIIT, Boxing and more. JetSweat Fitness Keeps You Going. Sign Up Today!"
          ></meta>
        </Helmet>
        <Switch>
          <Route
            path="/.well-known/apple-developer-merchantid-domain-association"
            component={AppleCertificate}
          />
          <AuthenticatedRouteCurry
            subscriberOnly
            path="/newplatform/"
            isLoggedIn={this.state.isLoggedIn}
            render={(props) => (
              <Suspense
                fallback={
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{ width: "100%", height: "100vh" }}
                  >
                    <Loader
                      color={
                        (whiteLabelFontStyles && whiteLabelFontStyles.color) ||
                        "#9ac8b9"
                      }
                    />
                  </div>
                }
              >
                {this.state.currUser && !this.state.isLoading ? (
                  <PlatformContainer
                    {...props}
                    {...this.props}
                    io={this.state.io}
                    refreshLoggedInUser={this.refreshLoggedInUser}
                    studio={this.state.whiteLabelDomainStudio}
                    whiteLabelBackgroundStyles={whiteLabelBackgroundStyles}
                    whiteLabelFontStyles={whiteLabelFontStyles}
                    isWhiteLabelDomain={this.state.whiteLabelDomain}
                    user={currUser}
                    logo={navBarLogo}
                  />
                ) : null}
              </Suspense>
            )}
          />

          <Route
            path="/contact"
            isLoggedIn={this.state.isLoggedIn}
            component={Contact}
          />
          <Route
            path="/faq"
            isLoggedIn={this.state.isLoggedIn}
            component={FAQ}
          />
          <Route
            path="/studios"
            studioId={whiteLabelDomainStudio.shortName}
            isLoggedIn={this.state.isLoggedIn}
            component={SingleStudioContainer}
          />

          {this.redirectToHomePage()}

          <Route
            path="/"
            render={(props) => (
              <LegacyNavbar
                {...props}
                {...this.state}
                whiteLabelBackgroundStyles={whiteLabelBackgroundStyles}
                whiteLabelFontStyles={whiteLabelFontStyles}
                whiteLabelDomain={this.state.whiteLabelDomain}
                studio={this.state.whiteLabelDomainStudio}
                isLoggedIn={this.state.isLoggedIn}
                setLogo={this.setLogo}
                whiteLabel={this.state.whiteLabel}

                // {...props}
                // {...this.props}
              />
            )}
          />
        </Switch>
        <Route
          path="/liveschedule"
          render={() => (
            <LiveSchedule
              setCurrUser={this.setCurrUser}
              currUser={this.state.currUser}
              whiteLabelBackgroundStyles={whiteLabelBackgroundStyles}
              whiteLabel={this.state.whiteLabel}
              studio={this.state.whiteLabelDomainStudio}
              loginFromToken={this.loginFromToken}
            />
          )}
        />
        <Route
          exact
          path="/specials/:promoName"
          render={(props) => (
            <PromoSignup
              {...props}
              currUser={currUser}
              loginFromToken={this.loginFromToken}
            />
          )}
        />
        <Route
          exact
          path="/newspecials/:promoName"
          render={(props) => (
            <NewPromoSignup
              {...props}
              currUser={currUser}
              loginFromToken={this.loginFromToken}
            />
          )}
        />

        <Route
          studio={whiteLabelDomainStudio.shortName}
          exact
          path="/daypass"
          render={(props) => (
            <WhiteLabelDaypass
              {...props}
              whiteLabelBackgroundStyles={whiteLabelBackgroundStyles}
              whiteLabelFontStyles={whiteLabelFontStyles}
              whiteLabelDomain={this.state.whiteLabelDomain}
              isLoggedIn={this.state.isLoggedIn}
              currUser={this.state.currUser}
              subscription={this.state.subscription}
              setSubscription={this.setSubscription}
              refreshLoggedInUser={this.refreshLoggedInUser}
              studio={whiteLabelDomainStudio}
            />
          )}
        />
        <Suspense
          fallback={
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ width: "100%", height: "100vh" }}
            >
              <Loader color="#9ac8b9" />
            </div>
          }
        >
          <Route
            exact
            path="/signup"
            render={(props) => (
              <WhiteLabelSignup
                {...props}
                whiteLabelBackgroundStyles={whiteLabelBackgroundStyles}
                whiteLabelFontStyles={whiteLabelFontStyles}
                whiteLabelDomain={this.state.whiteLabelDomain}
                studio={whiteLabelDomainStudio}
                currUser={currUser}
                loginFromToken={this.loginFromToken}
              />
            )}
          />
          <Route
            exact
            path="/signup/daypass"
            render={() => (
              <WhiteLabelSignup
                daypass
                whiteLabelDomain={this.state.whiteLabelDomain}
                whiteLabelBackgroundStyles={whiteLabelBackgroundStyles}
                whiteLabelFontStyles={whiteLabelFontStyles}
                studio={whiteLabelDomainStudio}
                currUser={currUser}
                loginFromToken={this.loginFromToken}
              />
            )}
          />
        </Suspense>
        <Route
          exact
          path="/signup/gympass"
          render={(props) => (
            <GymPassSignup
              {...props}
              setCurrUser={this.setCurrUser}
              currUser={currUser}
              isLoggedIn={this.state.isLoggedIn}
              loginFromToken={this.loginFromToken}
            />
          )}
        />
        <Suspense
          fallback={
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ width: "100%", height: "100vh" }}
            >
              <Loader color="#9ac8b9" />
            </div>
          }
        >
          <Route
            exact
            path={["/login", "/", `/login/${whiteLabelDomainStudio?.shortName}`]}
            render={(props) => (
              <WhiteLabelLogin
                {...props}
                whiteLabelBackgroundStyles={whiteLabelBackgroundStyles}
                whiteLabelFontStyles={whiteLabelFontStyles}
                whiteLabelDomain={this.state.whiteLabelDomain}
                isWhiteLabelDomain={this.state.whiteLabelDomain}
                studio={whiteLabelDomainStudio}
                setCurrUser={this.setCurrUser}
                isLoggedIn={this.state.isLoggedIn}
              />
            )}
          />
          <Route
            exact
            path="/login/daypass"
            render={(props) => (
              <WhiteLabelLogin
                {...props}
                daypass
                whiteLabelBackgroundStyles={whiteLabelBackgroundStyles}
                whiteLabelFontStyles={whiteLabelFontStyles}
                studio={whiteLabelDomainStudio}
                whiteLabelDomain={whiteLabelDomain}
                setCurrUser={this.setCurrUser}
                isLoggedIn={this.state.isLoggedIn}
              />
            )}
          />
          <AuthenticatedRouteCurry
            redirectSubscriber
            path="/checkout"
            render={(props) => {
              return (
                <WhiteLabelPaymentV3
                  {...props}
                  whiteLabelBackgroundStyles={whiteLabelBackgroundStyles}
                  whiteLabelFontStyles={whiteLabelFontStyles}
                  isLoggedIn={this.state.isLoggedIn}
                  currUser={this.state.currUser}
                  subscription={this.state.subscription}
                  setSubscription={this.setSubscription}
                  refreshLoggedInUser={this.refreshLoggedInUser}
                  whiteLabelDomain={this.whiteLabelDomain}
                  isWhiteLabelDomain={this.state.whiteLabelDomain}
                  studio={this.state.whiteLabelDomainStudio}
                />
              );
            }}
          />
        </Suspense>

        <Route
          path="/forgot-password"
          render={(props) => (
            <ForgotPassword
              {...props}
              whiteLabelDomainStudio={whiteLabelDomainStudio}
              whiteLabelBackgroundStyles={whiteLabelBackgroundStyles}
              whiteLabelFontStyles={whiteLabelFontStyles}
              studio={this.state.whiteLabelDomainStudio}
            />
          )}
        />
        <AuthenticatedRouteCurry
          redirectSubscriber
          path="/subscription"
          render={() => (
            <Subscription
              isLoggedIn={this.state.isLoggedIn}
              subscription={this.state.subscription}
              setSubscription={this.setSubscription}
            />
          )}
        />
        <Route
          path="/cancel"
          render={() => (
            <Cancel
              isLoggedIn={this.state.isLoggedIn}
              currUser={this.state.currUser}
              subscription={this.state.subscription}
            />
          )}
        />
        <AuthenticatedRouteCurry
          path="/account"
          render={(props) => (
            <Account
              {...props}
              studioName={whiteLabelDomainStudio.name}
              whiteLabelBackgroundStyles={whiteLabelBackgroundStyles}
              isWhiteLabelDomain={this.state.whiteLabelDomain}
              currUser={this.state.currUser}
            />
          )}
        />
        <AuthenticatedRouteCurry
          path="/confirmation"
          render={(props) => {
            if (!this.state.whiteLabelDomain) return <></>;
            return (
              <WhiteLabelCheckoutConfirmation
                {...props}
                studio={whiteLabelDomainStudio}
                whiteLabelBackgroundStyles={whiteLabelBackgroundStyles}
                currentUser={this.state.currUser}
              />
            );
          }}
        />
        <Route
          exact
          path="/logout"
          render={() => (
            <Logout match={this.props.match} logout={this.logout} />
          )}
        />
        <Suspense
          fallback={
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ width: "100%", height: "100vh" }}
            >
              <Loader color="#9ac8b9" />
            </div>
          }
        >
          <Route path="/terms" component={Terms} />
          <Route path="/privacypolicy" component={PrivacyPolicy} />
          <Route path="/contact" component={Contact} />
          <Route exact path="/how-it-works" component={Howitworks} />
          <Route path="/programs" component={Programs} />
          <Route exact path="/how-to-connect" component={Howtoconnect} />
          <Route path="/compatibledevices" component={Compatibledevices} />
          <Route path="/live" component={JetsweatLive} />
          <Route exact path="/studios" component={AllStudios} />
        </Suspense>
        <Route exact path="/explorestudio" component={ExploreStudio} />
        <Route exact path="/test" component={CalendarContainer} />
      </div>
    );
  }

  ///////////////////////// GET GENERAL ROUTES ////////////////////
  getGeneralRoutes() {
    const {
      isLoading,
      currUser,
      whiteLabelStudios,
      pageTitle,
      faviconSrc,
      whiteLabelDomain,
      whiteLabelDomainStudio,
      whiteLabelBackgroundStyles,
      whiteLabelFontStyles,
    } = this.state;

    const reload = () => window.location.reload();

    const AuthenticatedRouteCurry = (props) => (
      <AuthenticatedRoute
        {...props}
        isWhiteLabelDomain={this.state.whiteLabelDomain}
        isLoading={isLoading}
        currUser={currUser}
        isLoggedIn={this.state.isLoggedIn}
      />
    );

    return (
      <div>
        <Helmet>
          <title>{pageTitle}</title>
          <link rel="shortcut icon" href={faviconSrc} />
          <meta
            name="description"
            content="Stream The Top Fitness Classes Anytime, Anywhere. Yoga, Cardio, HIIT, Boxing and more. JetSweat Fitness Keeps You Going. Sign Up Today!"
          ></meta>
        </Helmet>
        <Switch>
          <Route
            exact
            path="/"
            render={() => (
              <Home setLogo={this.setLogo} studios={whiteLabelStudios} />
            )}
          />

          <Route
            path="/.well-known/apple-developer-merchantid-domain-association"
            component={AppleCertificate}
          />
          <AuthenticatedRouteCurry
            subscriberOnly
            path="/newplatform/"
            isLoggedIn={this.state.isLoggedIn}
            render={(props) => (
              <Suspense
                fallback={
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{ width: "100%", height: "100vh" }}
                  >
                    <Loader color="#9ac8b9" />
                  </div>
                }
              >
                <PlatformContainer
                  {...props}
                  {...this.props}
                  io={this.state.io}
                  refreshLoggedInUser={this.refreshLoggedInUser}
                  user={currUser}
                  studio={this.state.whiteLabelDomainStudio}
                  whiteLabelBackgroundStyles={whiteLabelBackgroundStyles}
                  whiteLabelFontStyles={whiteLabelFontStyles}
                  isWhiteLabelDomain={this.state.whiteLabelDomain}
                />
              </Suspense>
            )}
          />

          <Route
            path="/faq"
            isLoggedIn={this.state.isLoggedIn}
            component={FAQ}
          />
          <Route
            path="/studios/:studioName"
            isLoggedIn={this.state.isLoggedIn}
            component={SingleStudioContainer}
          />
          {this.redirectToHomePage()}
          <Route
            path="/"
            render={(props) => (
              <Navbar
                studio={this.state.whiteLabelDomainStudio}
                isLoggedIn={this.state.isLoggedIn}
                setLogo={this.setLogo}
                whiteLabel={this.state.whiteLabel}
                {...this.state}
              />
            )}
          />
        </Switch>
        <Route
          exact
          path="/specials/:promoName"
          render={(props) => (
            <PromoSignup
              {...props}
              currUser={currUser}
              loginFromToken={this.loginFromToken}
            />
          )}
        />
        <Route
          exact
          path="/newspecials/:promoName"
          render={(props) => (
            <NewPromoSignup
              {...props}
              currUser={currUser}
              loginFromToken={this.loginFromToken}
            />
          )}
        />

        <Suspense
          fallback={
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ width: "100%", height: "100vh" }}
            >
              <Loader color="#9ac8b9" />
            </div>
          }
        >
          <Route
            exact
            path="/signup"
            render={() =>
              this.state.whiteLabel ? (
                <WhiteLabelSignup
                  studio={this.state.whiteLabelDomainStudio}
                  currUser={currUser}
                  loginFromToken={this.loginFromToken}
                  whiteLabelDomain={whiteLabelDomain}
                  whiteLabelFontStyles={whiteLabelFontStyles}
                  whiteLabelBackgroundStyles={whiteLabelBackgroundStyles}
                />
              ) : (
                // <Suspense fallback={<Loader color={'#9ac8b9'}/>}>
                <Signup
                  currUser={currUser}
                  loginFromToken={this.loginFromToken}
                />
              )
            }
          />
        </Suspense>

        <Suspense
          fallback={
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ width: "100%", height: "100vh" }}
            >
              <Loader color="#9ac8b9" />
            </div>
          }
        >
          <Switch>
            <Route
              exact
              path="/iframe-sandbox"
              render={(props) => <Sandbox {...props} />}
            />
            <Route
              exact
              path="/login/daypass"
              render={(props) => (
                <Login
                  {...props}
                  setCurrUser={this.setCurrUser}
                  isLoggedIn={this.state.isLoggedIn}
                />
              )}
            />

            {whiteLabelStudios.map((studio) => {
              return (
                <Route
                  exact
                  path={`/login/${studio.shortName}`}
                  render={(props) => (
                    <Suspense
                      fallback={
                        <div
                          className="d-flex align-items-center justify-content-center"
                          style={{ width: "100%", height: "100vh" }}
                        >
                          <Loader color="#9ac8b9" />
                        </div>
                      }
                    >
                      <WhiteLabelLogin
                        {...props}
                        whiteLabelBackgroundStyles={whiteLabelBackgroundStyles}
                        whiteLabelFontStyles={whiteLabelFontStyles}
                        whiteLabelDomain={this.state.whiteLabelDomain}
                        studio={whiteLabelDomainStudio}
                        setCurrUser={this.setCurrUser}
                        isLoggedIn={this.state.isLoggedIn}
                      />
                    </Suspense>
                  )}
                />
              )
            })}

            {whiteLabelStudios.map((studio) => (
              <Route
                exact
                path={`/login/daypass/${studio.shortName}`}
                render={(props) => (
                  <Suspense
                    fallback={
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{ width: "100%", height: "100vh" }}
                      >
                        <Loader color="#9ac8b9" />
                      </div>
                    }
                  >
                    <WhiteLabelLogin
                      {...props}
                      daypass
                      setCurrUser={this.setCurrUser}
                      isLoggedIn={this.state.isLoggedIn}
                      whiteLabelBackgroundStyles={whiteLabelBackgroundStyles}
                      whiteLabelFontStyles={whiteLabelFontStyles}
                      whiteLabelDomain={this.state.whiteLabelDomain}
                      studio={whiteLabelDomainStudio}
                    />
                  </Suspense>
                )}
              />
            ))}
            <Route
              path={["/login", "/login:query"]}
              render={(props) =>
                this.state.whiteLabel ? (
                  <WhiteLabelLogin
                    {...props}
                    studio={this.state.whiteLabelDomainStudio}
                    currUser={currUser}
                    loginFromToken={this.loginFromToken}
                    whiteLabelDomain={whiteLabelDomain}
                    whiteLabelFontStyles={whiteLabelFontStyles}
                    setCurrUser={this.setCurrUser}
                    whiteLabelBackgroundStyles={whiteLabelBackgroundStyles}
                  />
                ) : (
                  <Login
                    {...props}
                    setCurrUser={this.setCurrUser}
                    isLoggedIn={this.state.isLoggedIn}
                  />
                )
              }
            />
            {/* <Route component={UnknownPage} /> */}
          </Switch>

          <AuthenticatedRouteCurry
            redirectSubscriber
            path="/payment"
            render={(props) => (
              <Payment
                {...props}
                isLoggedIn={this.state.isLoggedIn}
                currUser={this.state.currUser}
                subscription={this.state.subscription}
                setSubscription={this.setSubscription}
                refreshLoggedInUser={this.refreshLoggedInUser}
              />
            )}
          />
        </Suspense>
        {whiteLabelStudios.map((studio) => (
          <AuthenticatedRouteCurry
            redirectSubscriber
            path={`/checkout/${studio.shortName}`}
            render={(props) => (
              <Suspense
                fallback={
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{ width: "100%", height: "100vh" }}
                  >
                    <Loader color="#9ac8b9" />
                  </div>
                }
              >
                <WhiteLabelPayment
                  {...props}
                  isLoggedIn={this.state.isLoggedIn}
                  currUser={this.state.currUser}
                  subscription={this.state.subscription}
                  setSubscription={this.setSubscription}
                  refreshLoggedInUser={this.refreshLoggedInUser}
                  studio={studio}
                />
              </Suspense>
            )}
          />
        ))}

        {/* WHITE LABEL SIGNUP/LOGIN/PAYMENT ROUTES - MONTHLY/YEARLY SUBS */}
        <Suspense
          fallback={
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ width: "100%", height: "100vh" }}
            >
              <Loader color="#9ac8b9" />
            </div>
          }
        >
          <Route
            exact
            path="/daypass"
            render={(props) => (
              <Login
                {...props}
                setCurrUser={this.setCurrUser}
                isLoggedIn={this.state.isLoggedIn}
              />
            )}
          />

          <Route
            exact
            path="/signup/daypass"
            render={() => (
              <Signup
                currUser={currUser}
                loginFromToken={this.loginFromToken}
              />
            )}
          />
        </Suspense>

        {whiteLabelStudios.map((studio) => (
          <Route
            studio={studio.shortName}
            exact
            path={`/daypass/${studio.shortName}`}
            render={(props) => (
              <WhiteLabelDaypass
                {...props}
                isLoggedIn={this.state.isLoggedIn}
                currUser={this.state.currUser}
                subscription={this.state.subscription}
                setSubscription={this.setSubscription}
                refreshLoggedInUser={this.refreshLoggedInUser}
                studio={studio}
              />
            )}
          />
        ))}

        {whiteLabelStudios.map((studio) => (
          <Route
            exact
            path={`/signup/${studio.shortName}`}
            render={() => (
              <Suspense
                fallback={
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{ width: "100%", height: "100vh" }}
                  >
                    <Loader color="#9ac8b9" />
                  </div>
                }
              >
                <WhiteLabelSignup
                  studio={studio}
                  currUser={currUser}
                  loginFromToken={this.loginFromToken}
                  whiteLabelDomain={whiteLabelDomain}
                  whiteLabelFontStyles={whiteLabelFontStyles}
                  whiteLabelBackgroundStyles={whiteLabelBackgroundStyles}
                />
              </Suspense>
            )}
          />
        ))}

        {whiteLabelStudios.map((studio) => (
          <Route
            exact
            path={`/signup/daypass/${studio.shortName}`}
            render={() => (
              <Suspense
                fallback={
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{ width: "100%", height: "100vh" }}
                  >
                    <Loader color="#9ac8b9" />
                  </div>
                }
              >
                <WhiteLabelSignup
                  daypass
                  studio={studio}
                  currUser={currUser}
                  loginFromToken={this.loginFromToken}
                  whiteLabelDomain={whiteLabelDomain}
                  whiteLabelFontStyles={whiteLabelFontStyles}
                  whiteLabelBackgroundStyles={whiteLabelBackgroundStyles}
                />
              </Suspense>
            )}
          />
        ))}

        <Route
          exact
          path="/signup/gympass"
          render={(props) => (
            <GymPassSignup
              {...props}
              setCurrUser={this.setCurrUser}
              currUser={currUser}
              isLoggedIn={this.state.isLoggedIn}
              loginFromToken={this.loginFromToken}
            />
          )}
        />

        <Route
          path="/forgot-password"
          render={(props) => <ForgotPassword {...props} />}
        />

        <AuthenticatedRouteCurry
          redirectSubscriber
          path="/subscription"
          render={() => (
            <Subscription
              isLoggedIn={this.state.isLoggedIn}
              subscription={this.state.subscription}
              setSubscription={this.setSubscription}
            />
          )}
        />
        <Route
          path="/cancel"
          render={() => (
            <Cancel
              isLoggedIn={this.state.isLoggedIn}
              currUser={this.state.currUser}
              subscription={this.state.subscription}
            />
          )}
        />
        <AuthenticatedRouteCurry
          path="/account"
          render={() => <Account currUser={this.state.currUser} />}
        />
        <Route
          exact
          path="/logout"
          render={() => (
            <Logout match={this.props.match} logout={this.logout} />
          )}
        />
        <Route
          path="/logout/:studio"
          render={() => (
            <Logout match={this.props.match} logout={this.logout} />
          )}
        />
        <Route
          path="./well-known/apple-developer-merchantid-domain-association"
          onEnter={reload}
        />
        <Route path="/whoweserve/employers" component={WorkplaceWellness} />
        <Route path="/whoweserve/healthplans" component={Healthcare} />
        <Route path="/whoweserve/schools" component={Education} />
        <Route path="/terms" component={Terms} />
        <Route path="/privacypolicy" component={PrivacyPolicy} />
        <Route path="/contact" component={Contact} />
        <Suspense
          fallback={
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ width: "100%", height: "100vh" }}
            >
              <Loader color="#9ac8b9" />
            </div>
          }
        >
          <Route path="/programs" component={Programs} />
          <Route path="/how-it-works" component={Howitworks} />
          <Route exact path="/how-to-connect" component={Howtoconnect} />
          <Route
            path="/liveschedule"
            render={() => (
              <LiveSchedule
                setCurrUser={this.setCurrUser}
                currUser={this.state.currUser}
                whiteLabelBackgroundStyles={whiteLabelBackgroundStyles}
                whiteLabel={this.state.whiteLabel}
                studio={this.state.whiteLabelDomainStudio}
                loginFromToken={this.loginFromToken}
              />
            )}
          />
          <Route path="/compatibledevices" component={Compatibledevices} />
          <Route path="/live" component={JetsweatLive} />
          <Route exact path="/studios" component={AllStudios} />
        </Suspense>
        <Route exact path="/explorestudio" component={ExploreStudio} />
        <Route exact path="/test" component={CalendarContainer} />
      </div>
    );
  }

  render() {
    const options = {
      autoConfig: true,
      debug: false,
    };

    // ReactPixel.init(process.env.FACEBOOK_PIXEL_ID, options);
    ReactPixel.init("2783789165281965", options);

    ReactPixel.pageView();

    if (this.state.whiteLabelDomain) {
      return this.getWhiteLabelDomainRoutes();
    }

    return this.getGeneralRoutes();
  }
}

const mapStateToProps = (state) => ({
  GoogleAuth: state.calendarReducer.GoogleAuth,
  OutlookAuth: state.calendarReducer.OutlookAuth,
  iCalendarAuth: state.calendarReducer.iCalendarAuth,
  liveClassBookingSuccess: state.liveclass.success,
});

const mapDispatchToProps = (dispatch) => ({
  settingAuth: (calendarAuth, currUser, whiteLabelDomain) =>
    dispatch(settingAuth(calendarAuth, currUser, whiteLabelDomain)),
  syncing: (calendar) => dispatch(syncing(calendar)),
  unsynced: (calendar) => dispatch(unsynced(calendar)),
  synced: (calendar) => dispatch(synced(calendar)),
  clearSignOut: () => dispatch(clearSignOut()),
  clearSignIn: () => dispatch(clearSignIn()),
});
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(App);
