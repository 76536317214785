import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';

class Logout extends Component {
  constructor(props) {
    super(props);
    this.logout = this.props.logout;
  }
  componentDidMount() {
    this.props.logout();
  }

  render() {
    ReactPixel.track('LOG-OUT', null);
    const studio = window.location.pathname.split("/")[2];
    return studio ? <Redirect to={`/login/${studio}`} /> : <Redirect to="/" />;
  }
}

export default Logout;
