import React from "react";
import ReactModal from "react-modal";
import { withRouter } from "react-router-dom";
import "./VideoPlayer/modalexample.css";
import closeButton from "../images/closeButton.png";
import { render } from "@testing-library/react";

// Required props:
// showModal --> whether the modal is visible
// closeModal --> function to close the modal
// title --> value string
// Optional props:
// subTitle --> expects a video with title subproperty
// preventCloseModal --> type = boolean, whether the modal should close on outside click
const JetsweatModal = (props) => {
  const {
    subTitle,
    preventCloseModal,
    noActionModal,
    closeModal,
    whiteLabelBackgroundStyles,
    xIcon,
    titleWidth,
  } = props;

  const customStyles = {
    content: {
      borderRadius: "10px",
    },
    overlay: {
      zIndex: 10000,
      overflow: "scroll",
      paddingBottom: "60px",
      // add this atribute to overlay for a transparent overlay
      ...(noActionModal && { backgroundColor: "rgb(0,0,0,0)" }),
    },
  };
  const titleStyle = {
    color: "white",
    textAlign: "center",
    fontSize: noActionModal ? null : "26px",
    fontWeight: "bold",
  };

  const subTitleStyle = {
    color: "white",
    fontSize: 18,
    textAlign: "center",
  };

  const titleContent = {
    // background:
    //   props.whiteLabelBackgroundStyles &&
    //   props.whiteLabelBackgroundStyles.backgroundColor
    //     ? props.whiteLabelBackgroundStyles.backgroundColor
    //     : `#9AC8B9`,

    // linear-gradient(180deg, #9AC8B9 0%, rgba(154, 200, 185, 0) 181.94%)`,
    width: "100%",
    fontSize: 16,
    paddingBottom: 20,
    paddingTop: xIcon ? 0 : 30,
    opacity: 0.9,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    marginTop: -10
  };

  const modalBackgroundColor = whiteLabelBackgroundStyles && whiteLabelBackgroundStyles.backgroundColor
    ? whiteLabelBackgroundStyles.backgroundColor : '#9ac8b9';

  return (
    <ReactModal
      isOpen={props.showModal}
      onRequestClose={props.closeModal}
      shouldCloseOnOverlayClick={preventCloseModal ? false : true}
      className={`${
        noActionModal
          ? "global-jetsweat-modal-no-action"
          : "global-jetsweat-modal"
      }`}
      style={{
        ...customStyles,
        ...props.customStyles,
      }}
    >
      {noActionModal ? (
        // for Modal with no action
        
        <div
          style={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            background: modalBackgroundColor
          }}
        >
          <div style={titleContent}>
            <div
              style={titleStyle}
              className="jetsweat-global-modal-title-no-action"
            >
              {/* close icon */}
              <div onClick={closeModal} className="modal-close-icon hover"></div>
              {props.title}
            </div>
            <div style={subTitleStyle}>
              {subTitle ? subTitle.title : undefined}
            </div>
          </div>
        </div>
      ) : (
        // for Modal with action  
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >   
      <div style={{
        width: "100%",
        height: "100%",
        backgroundImage: `linear-gradient(180deg, ${modalBackgroundColor} 0%, rgba(154, 200, 185, 0) 181.94%)`
      }}>
      <div style={{bottom: -10, right: 10}}>
        {xIcon ? (
        <div style={{display:"flex", justifyContent:"flex-end", width: "100%", height: 32 }}>
          <div onClick={closeModal} style={{fontWeight: "bold", fontSize: 20,
          color: "white", fontFamily: "Coco Gothic"}}>
          X
          </div>
        </div>
        ):(null)}
      </div>
      
      <div style={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%"}}>  
        <div style={{display: "flex", justifyContent: "center", alignItems: "center", width: `${titleWidth}`}}>
          <div style={titleContent}>
              <div
                style={titleStyle}
                className={
                  props.match.path == "/liveschedule"
                    ? "jetsweat-liveschedule-title"
                    : "jetsweat-global-modal-title"
                }
              >
                {props.title}
              </div>
            <div style={subTitleStyle}>
              {subTitle ? subTitle.title : undefined}
            </div>
           </div>
          </div>
        </div>
      </div>

          <div
            className="jetsweat-global-modal-attributes"
            style={{ padding: 5 }}
          >
            {props.children}
          </div>
        </div>
      )}
    </ReactModal>
  );
};

export default withRouter(JetsweatModal);
