import React from "react";
import { Route, Redirect } from "react-router-dom";
import queryString from 'query-string';

export default function AuthenticatedRoute(props) {
  const {
    isLoading,
    subscriberOnly,
    redirectSubscriber,
    loader,
    location,
    currUser,
    path,
    isLoggedIn,
    PurchasedClass,
    isWhiteLabelDomain
  } = props;

  const renderPage = () => {
    return <Route {...props} />;
  };

  const pathName = (location ?? {})?.pathname ?? window?.location?.pathname ?? '';
  const hasPathName = pathName.length > 1;

  const searchQuery = window?.location?.search ?? '';
  const searchParams = queryString.parse(searchQuery);
  const redirectPath = searchParams?.redirect_path;

  const existingRedirectParameter = redirectPath ?
      `?redirect_path=${redirectPath}` : '';

  const newRedirectParameter = hasPathName ? `?redirect_path=${pathName}${searchQuery}` : '';
  const newSearchQuery = existingRedirectParameter.length > 0 ? existingRedirectParameter : newRedirectParameter;

  if (isLoading) {
    // A "loader" component can be displayed while loading user info.
    return <Route path={path} render={() => loader || <div />} />;
  } else if (!currUser) {
    return (
      <Route
        path={path}
        render={(props) => {
          //debugger
          if (isLoggedIn && currUser.authToken) {
            return renderPage();
          } else {
            return <Redirect to={`/login${newSearchQuery}`} />;
          }
        }}
      />
    );
  }

  let redirectToPath = '/payment' + newSearchQuery;
  if (isWhiteLabelDomain) {
    redirectToPath = '/checkout' + newSearchQuery;
  }

  if (existingRedirectParameter.includes('checkout') || existingRedirectParameter.includes('payment')) {
    redirectToPath = redirectPath
  }

  if (isLoggedIn) {
    const isSubscribed = isWhiteLabelDomain ?
        (currUser?.isCustomer || currUser?.userType === 'internal') :
        Boolean(currUser.subscription && currUser.subscription.active);
    if (subscriberOnly && !isSubscribed) {
      // For logged in users, redirect to payment if its a subscriber only route.
      return <Route path={path} render={() => <Redirect to={redirectToPath} />} />;
    }
  
    if(!isSubscribed && window.location.href.indexOf("upcoming") > -1) {
      return <Route path={path} render={() => <Redirect to={redirectToPath} />} />;
    }
  
    if (redirectSubscriber && isSubscribed) {
      return (
        <Route
          path={path}
          render={() => <Redirect to="/newplatform/dashboard" />}
        />
      );
    }
  }
  return <Route path={path} render={() => <Route {...props} />} />;
}
