import { ActionTypes } from '../../actions/favoritestudios.actions'

const initialState = {
    faveStudios: [],
    mapStudioIdToInstanceId: undefined,
    error: {}
}

const favoritestudiosReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.GET_ALL_FAVE_STUDIOS_SUCCESS:
            const mapStudioIdToInstanceId = action.faveStudios.reduce((prevState, currState) => ({ ...prevState, [currState.studioId.id]: currState.id }), {})
            return {
                ...state,
                error: {},
                faveStudios: action.faveStudios,
                mapStudioIdToInstanceId: mapStudioIdToInstanceId
            }


        case ActionTypes.GET_ALL_FAVE_STUDIOS_FAILURE:
            return {
                ...state,
                error: { ...action.error }
            }

        case ActionTypes.ADD_FAVORITE_STUDIOS_SUCCESS:
            const newInstances = [...action.newInstance].reduce((prevState, currState) => ({...prevState, [currState.studioId]: currState.id}), {})
            return {
                ...state,
                mapStudioIdToInstanceId: {...state.mapStudioIdToInstanceId, ...newInstances},
                error: {}
            }
        
        case ActionTypes.ADD_FAVORITE_STUDIOS_FAILURE:
            return {
                ...state,
                error: {...action.error}
            }
        
        case ActionTypes.UNFAVORITE_STUDIOS_SUCCESS:
            const updatedMapStudioIdToInstanceId = {...state.mapStudioIdToInstanceId}
            const deletedStudioId = [...action.deleted].map(deleted => deleted.studioId)
                    .forEach(deletedstudioId => delete updatedMapStudioIdToInstanceId[""+deletedstudioId])
 
            return {
                ...state,
                mapStudioIdToInstanceId: updatedMapStudioIdToInstanceId,
                error: deletedStudioId
            }
        
        case ActionTypes.UNFAVORITE_STUDIOS_FAILURE:
            return {
                ...state,
                error: {...action.error}
            }

        default:
            return state;
    }

}

export default favoritestudiosReducer;
