import { ActionTypes } from "redux/actions/favoriteclasstypes.actions"

const initialState = {
    faveClassTypes: [],
    mapClassIdToInstanceId: {},
    error: {}
}

const favoriteclasstypesReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.GET_ALL_FAVE_CLASSTYPES_SUCCESS:
            let faveClasstypes = [...action.favoriteClasstypes]
            let mapClassIdToInstanceId = faveClasstypes.reduce((prevState, currState) => ({ ...prevState, [currState.classId.id]: currState.id }), {})
            //let mapClassIdToInstanceId = faveClasstypes.map(classTypes => ({ [classTypes.classId.id]: classTypes.id }))
            return {
                ...state,
                error: {},
                faveClassTypes: action.favoriteClasstypes,
                mapClassIdToInstanceId: mapClassIdToInstanceId
            }
        case ActionTypes.GET_ALL_FAVE_CLASSTYPES_FAILURE:
            return {
                ...state,
                error: { ...action.error }
            }
        case ActionTypes.ADD_FAVORITE_CLASSTYPES_SUCCESS:
            const newRecords = [...action.newRecord].reduce((prevState, currState) => ({ ...prevState, [currState.classId]: currState.id }), {})
            return {
                ...state,
                //think about this again
                faveClassTypes: [...state.faveClassTypes, ...action.newRecord],
                mapClassIdToInstanceId: { ...state.mapClassIdToInstanceId, ...newRecords },
                error: {}
            }
        case ActionTypes.ADD_FAVORITE_CLASSTYPES_FAILURE:
            return {
                ...state,
                error: { ...action.error }
            }
        case ActionTypes.ADD_THEN_GET_FAVE_CLASSTYPES_FAILURE:
            return {
                ...state,
                error: { ...action.error }
            }
        case ActionTypes.UNFAVORITE_CLASSTYPES_FAILURE:
            return {
                ...state,
                error: {...action.error}
            }
        case ActionTypes.UNFAVORITE_CLASSTYPES_SUCCESS:
            const updatedMapClassIdToInstanceId = {...state.mapClassIdToInstanceId}
            const deletedClassId = [...action.deleted].map(deleted => deleted.classId)
                .forEach(deletedclassId => delete updatedMapClassIdToInstanceId[""+deletedclassId])
            return {
                ...state,
                mapClassIdToInstanceId: updatedMapClassIdToInstanceId,
                error: {}

            }
        default:
            return state
    }
}

export default favoriteclasstypesReducer