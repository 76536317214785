import React from 'react';

export default function RenderTestimonials({ testimonials, testimonialIcon }) {
  // From local: testimonial.text, testimonial.author
  // From DB: testimonial.feedback, testimonial.name
  const smallerIconStyle = {
    backgroundImage: `url("${testimonialIcon}")`
  }

  const mapTestimonials = testimonials.map((testimonial, i) => (
    <p className="smaller-icon" style={smallerIconStyle} key={`testimonial-${i}`}>
      {testimonial.feedback}
      <strong>
        <br />
        {`- ${testimonial.name}`}
      </strong>
    </p>
  ));

  return testimonials.length == 0 ? null : (
    <>
      <p className="section-title">What People Are Saying</p>
      <div className="whatpeopearesayinggrid">
        {mapTestimonials}
      </div>
    </>
  )
}
