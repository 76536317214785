import {
  GET_ALL_LIVE_VIDEOS_JETSWEAT,
  GET_ALL_LIVE_VIDEOS_JETSWEAT_SUCCESS,
  GET_ALL_LIVE_VIDEOS_JETSWEAT_FAIL,
  GET_LIVE_CONTENT_ID,
  GET_LIVE_CONTENT_ID_SUCCESS,
  GET_LIVE_CONTENT_ID_FAIL,
  SCHEDULE_LIVE_CLASSES,
  SCHEDULE_LIVE_CLASSES_SUCCESS,
  SCHEDULE_LIVE_CLASSES_FAIL,
} from '../../actions/live.actions'
import { takeLatest, call, all, put } from 'redux-saga/effects'
import { post } from '../../api'
const moment = require('moment')




export const getAllLiveVideos = function* (action) {
  const { authToken } = action
  try {
    const response = yield call(
      post,
      '/api/liveclass/upcoming',
      {},
      {
        Authorization: authToken,
      }
    )
    const data = yield response.json()
    data = data.content

    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_ALL_LIVE_VIDEOS_JETSWEAT_SUCCESS,
        liveVideos: data,
      })
    } else {
      throw data
    }
  } catch (error) {
    yield put({ type: GET_ALL_LIVE_VIDEOS_JETSWEAT_FAIL, error: error })
  }
}
export const getLiveContentId = function* (action) {
  const { authToken, id } = action
  try {
    const response = yield call(
      post,
      `/api/livestream/channel/channelId/${id}`,
      {},
      {
        Authorization: authToken,
      }
    )
    const data = yield response.json()
    data = data.content

    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: GET_LIVE_CONTENT_ID_SUCCESS,
        id: data,
      })
    } else {
      throw data
    }
  } catch (error) {
    yield put({ type: GET_LIVE_CONTENT_ID_FAIL, error: error })
  }
}
export const getScheduledLiveClasses = function* (action) {
  const { authUserInfo, id, time } = action
  try {
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ]

    const dateObj = new Date(time)
    const date = moment(dateObj).utc().startOf('day').toISOString()

    const response = yield call(
      post,
      '/api/workout/add',
      {
        authUserInfo,
        videodId: null,
        liveClassId: id,
        status: 'scheduled',
        when: date,
      },
      {
        Authorization: authUserInfo.authToken,
      }
    )
    const data = yield response.json()
    data = data.content

    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: SCHEDULE_LIVE_CLASSES_SUCCESS,
        liveClasses: data,
      })
    } else {
      throw data
    }
  } catch (error) {
    yield put({ type: SCHEDULE_LIVE_CLASSES_FAIL, error: error })
  }
}

function* live() {
  yield all([
    takeLatest(GET_ALL_LIVE_VIDEOS_JETSWEAT, getAllLiveVideos),
    takeLatest(GET_LIVE_CONTENT_ID, getLiveContentId),
    takeLatest(SCHEDULE_LIVE_CLASSES, getScheduledLiveClasses),
  ])
}
export default live
