import { ActionTypes } from "redux/actions/favoritestudios.actions"
import { takeLatest, call, all, put, takeEvery } from 'redux-saga/effects'
import { post } from '../../api'

export const getFaveStudios = function* (action) {
    const { headers } = action.payload

    try {
        const response = yield call(
            post,
            '/api/favoriteStudios/myFavoriteStudios',
            {},
            {
                authorization: headers
            }
        )
        
        const data = yield response.json()
        const allfaveStudios = data.content
        //debugger
        if (response.status >= 200 && response.status < 300 && data.status) {
            yield put({
                type: ActionTypes.GET_ALL_FAVE_STUDIOS_SUCCESS,
                faveStudios: allfaveStudios
            })
        }

    } catch (error) {
        yield put({
            type: ActionTypes.GET_ALL_FAVE_STUDIOS_FAILURE, error: error
        })
    }
}

export const addFaveStudios = function* (action) {
    const { headers, studioId, callback } = action.payload

    try {
        const response = yield call(
            post,
            '/api/favoriteStudios/add',
            {
                studioId: studioId
            },
            {
                authorization: headers
            }
        )

        const data = yield response.json()
        debugger

        if (callback && data.status) {
            callback()
        }

        if (response.status >= 200 && response.status < 300 && data.status) {
            yield put({
                type: ActionTypes.ADD_FAVORITE_STUDIOS_SUCCESS,
                newInstance: data.content
            })
        } else 
            throw data

    } catch (error) {
        yield put({
            type: ActionTypes.ADD_FAVORITE_STUDIOS_FAILURE,
            error: error
        })
    }

}

export const unfavoriteStudios = function* (action) {
    const {headers, instanceId, callback} = action.payload

    try {
        const response = yield call(
            post,
            '/api/favoriteStudios/delete',
            {
                id: instanceId
            },
            {
                authorization: headers
            }
        )

        const data = yield response.json()
        
        if (callback && data.status) {
            callback()
        }

        if (response.status >= 200 && response.status < 300 && data.status) {
            debugger
            yield put({
                type: ActionTypes.UNFAVORITE_STUDIOS_SUCCESS,
                deleted: data.content
            })
        } else 
            throw data

    } catch (error) {
        yield put({
            type: ActionTypes.UNFAVORITE_STUDIOS_FAILURE, error: error
        })
    }
    
}

function* favoritestudios() {
    yield all([
        takeLatest(ActionTypes.GET_ALL_FAVE_STUDIOS, getFaveStudios),
        takeEvery(ActionTypes.UNFAVORITE_STUDIOS, unfavoriteStudios),
        takeEvery(ActionTypes.ADD_FAVORITE_STUDIOS, addFaveStudios)
    ])
}

export default favoritestudios