import React, { Component } from "react"
import { Link } from "react-router-dom"
import "./footer.css"

class Footer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      email: "",
      // whiteLabel: this.props.studio
    }
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(event) {
    const fieldName = event.target.name
    this.setState({ [fieldName]: event.target.value })
  }

  render() {
    const whiteLabelFooter = (
      <div className="verticalbars-whitelabel">
        <div className="whiteLabel-col">
          <div className="top-col">
            <div className="left-col">
              <Link to="/privacypolicy" className="custom-footer-text">
                {" "}
                Privacy Policy{" "}
              </Link>
            </div>
            <div className="right-col">
              <Link to="/terms" className="custom-footer-text">
                Terms of Service
              </Link>
            </div>
          </div>
          <div className="bot-col">
            <p className="contact-text">
              Contact us at{" "}
              {this.props.studio && this.props.studio.contactEmail
                ? this.props.studio.contactEmail
                : "support@jetsweatfitness.com"}
            </p>
          </div>
        </div>
      </div>
    )
    return (
      <div id="footer">
        {/* <div className="flex-wrapper"> */}
        <footer className=" Footer-container">
          {this.props.studio ? (
            whiteLabelFooter
          ) : (
            <>
              <div id="c1">
                <Link to="/privacypolicy" className="custom-footer-text">
                  {" "}
                  Privacy Policy{" "}
                </Link>
                <br />
                <Link to="/terms" className="custom-footer-text">
                  Terms of Service
                </Link>
                <br />
                <Link to="/contact" className="custom-footer-text">
                  Contact Us
                </Link>
              </div>
              <div id="c2">
                <div className="verticalbars">
                  <form
                    action="https://jetsweatapp.us15.list-manage.com/subscribe/post?u=e4e72a779acceb6ff0e5299e3&amp;id=5a674adfc1"
                    method="post"
                    id="mc-embedded-subscribe-form"
                    name="mc-embedded-subscribe-form"
                    className="validate"
                    target="_blank"
                    noValidate
                  >
                    <p className="custom-footer-text">
                      Stay up to date with our news and announcements
                    </p>
                    <div
                      className="mc-field-group"
                      style={{ marginLeft: "10px" }}
                    >
                      <input
                        className="emailinput"
                        type="email"
                        autoCapitalize="off"
                        autoCorrect="off"
                        name="MERGE0"
                        id="MERGE0"
                        size="25"
                        placeholder="Enter email address"
                      />
                    </div>

                    <div id="mce-responses" className="clear">
                      <div
                        className="response"
                        id="mce-error-response"
                        style={{ display: "none" }}
                      />
                      <div
                        className="response"
                        id="mce-success-response"
                        style={{ display: "none" }}
                      />
                    </div>
                    <div
                      style={{ position: "absolute", left: "-5000px" }}
                      aria-hidden="true"
                    >
                      <input
                        type="text"
                        name="b_e4e72a779acceb6ff0e5299e3_5a674adfc1"
                        tabIndex="-1"
                      />
                    </div>

                    <input
                      type="submit"
                      onClick={this.handleSubmit}
                      style={{
                        padding: "10px",
                        borderRadius: "2px",
                        marginTop: "5px",
                        marginLeft: "10px",
                      }}
                      className="formEmailButton"
                      name="submit"
                      value="Subscribe"
                    />
                  </form>
                </div>
              </div>
              <div id="c3">
                <a
                  href="https://www.facebook.com/jetsweatfitness"
                  className="custom-footer-text"
                >
                  Facebook
                </a>
                <br />
                <a
                  href="https://www.instagram.com/jetsweatfitness/"
                  className="custom-footer-text"
                >
                  Instagram
                </a>
                <br />
                <a
                  href="https://www.jetsweat.io/contact-us"
                  className="custom-footer-text"
                >
                  Become a Wellness Partner
                </a>
                <br />
                <a
                  href="https://www.blog.jetsweatfitness.com/"
                  className="custom-footer-text"
                >
                  Blog
                </a>
                <br />
                <a
                  href="https://www.blog.jetsweatfitness.com/new-products/"
                  className="custom-footer-text"
                >
                  Gear
                </a>
              </div>
              <div id="c4">
                <a href="https://itunes.apple.com/us/app/jetsweat/id1262223853?mt=8">
                  <img
                    alt="AppStore Download"
                    src={require("../images/DownloadOnAppStore.png")}
                    className="downloadButton"
                  />
                </a>
                <br />

                <a href="https://channelstore.roku.com/details/16f509e65f017eaed463030b99a47b50/jetsweat">
                  <img
                    alt="Heroku Download"
                    src={require("../images/DownloadOnRokuPurple.png")}
                    className="downloadButton"
                  />
                </a>
              </div>
            </>
          )}
        </footer>
      </div>
      // </div>
    )
  }
}

export default Footer
