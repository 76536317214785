import React from "react";

const HowWorks = ({ howItWorks }) => (
  <section className="how-it-works">
    <p className="h2 section-title mt-0 ml-0">How Does it Work</p>
    <div className="pure-g">
      <div className="pure-u-md-1-2 pure-u-sm-1 box-1-1 vertical-center">
        <p className="font-size-md">
          Around the world on demand workouts curated for outside the studio.
        </p>
        <p className="font-size-md">
          Choose from monthly or annual subscriptions.
        </p>
        <p className="font-size-md">
          Stream exclusive HD videos through mobile, web, or TV
        </p>
        <p className="font-size-md">
          Take your workout wherever, whenever, No excuses.
        </p>
      </div>
      <div className="pure-u-md-1-2 pure-u-sm-1 box-1-1">
        <img
          className="pure-img"
          data-aos="fade-in"
          src={howItWorks}
          alt="How it works"
        />
      </div>
    </div>
  </section>
);

export default HowWorks;
