import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Footer from './footer.js';
import './cancel.css';
import branch from 'branch-sdk';


class Cancel extends Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.state.isLoggedIn = props.isLoggedIn;
    this.state.currUser = props.currUser;
    this.state.subscription = props.subscription;
    this.cancelSubscription = this.cancelSubscription.bind(this);
  }

  cancelSubscription() {
    if (!this.state.currUser) {
      return (
        <Redirect to="/" />
      );
    }

    const self = this;
    // stripe cancel api
    fetch('/api/user/subscription',
      {
        method: 'delete',
        headers: {
          'Content-type': 'application/json',
          Authorization: this.state.currUser.authToken,
        },
      })
      .then((res) => res.json())
      .then((resp) => {
        if (resp && resp.status) {
          //  Check response type array or object.
          const subscription = Array.isArray(resp.content) ? resp.content[0] : resp.content.subscriptionRecord;

          const isYearly = subscription.packageId.match(/year/);
          const isTrial = subscription.trial === true;
          const isCancelled = subscription.cancelled === true;

          // Only track in branch if cancellation is within trial period 7days or custom.
          if (isTrial && isCancelled) {
            branch.track(isYearly ? 'cancelled-yearly' : 'cancelled-monthly', {
              price: subscription.price,
              stripeId: subscription.stripeSubscriptionId,
            });
          }

          self.setCurrUser(self.state.currUser);
        } else {
          self.logout();
        }
      })
      .catch(() => {
        self.logout();
      });
  }

  render() {
    return (
      <div>
        <div className="Background-color-nav" />

        <h1 className="Center Cancel-text">Cancel Subscription</h1>
        <section className="Z-index10">

          <div className="Center ">
				Are you sure you want to cancel?  Email us if you have any question by our
            {' '}
            <a href="mailto:support@jetsweat.com">support email</a>
.
          </div>

          <div className="clearfix" />

          <div className="Cancel-subscription">
            <button className="Button-cancel" onClick={this.cancelSubscription}>Cancel Subscription</button>
          </div>

          <div className="cancelspace" />

        </section>

        <Footer />
      </div>
    );
  }
}

export default Cancel;
