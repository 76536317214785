export const ActionTypes = {
  JOIN_LIVE_CLASS: 'JOIN_LIVE_CLASS',
  JOIN_LIVE_CLASS_SUCCESS: 'JOIN_LIVE_CLASS_SUCCESS',
  JOIN_LIVE_CLASS_FAILURE: 'JOIN_LIVE_CLASS_FAILURE',
  LEAVE_LIVE_CLASS: 'LEAVE_LIVE_CLASS',
  LEAVE_LIVE_CLASS_SUCCESS: 'LEAVE_LIVE_CLASS_SUCCESS',
  LEAVE_LIVE_CLASS_FAILURE: 'LEAVE_LIVE_CLASS_FAILURE',
}

export const joinLiveClass = function (liveClassId, userAgent, browser) {
  return {
    type: ActionTypes.JOIN_LIVE_CLASS,
    payload: {
      liveClassId,
      userAgent,
      browser
    }
  }
}


export const leaveLiveClass = function (liveClassAttendanceId, cameraOn, cameraOnDurationSeconds) {
  return {
    type: ActionTypes.LEAVE_LIVE_CLASS,
    payload: {
      liveClassAttendanceId,
      cameraOn,
      cameraOnDurationSeconds
    }
  }
}
