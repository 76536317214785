
export const GET_ALL_FAVORITES_VIDEO = 'GET_ALL_FAVORTIES_VIDEO'
export const GET_ALL_FAVORITES_VIDEO_SUCCESS = 'GET_ALL_FAVORTIES_VIDEO_SUCCESS'
export const GET_ALL_FAVORITES_VIDEO_FAIL = 'GET_ALL_FAVORTIES_VIDEO_FAIL'
export const ADD_FAVORITE_VIDEO = 'ADD_FAVORITE_VIDEO'
export const ADD_FAVORITE_VIDEO_FAILURE = 'ADD_FAVORITE_VIDEO_FAILURE'
export const ADD_FAVORITE_VIDEO_SUCCESS = 'ADD_FAVORITE_VIDEO_SUCCESS'
export const ADD_FAVORITE_VIDEO_PENDING = 'ADD_FAVORITE_VIDEO_PENDING'
export const ADD_THEN_UPDATE_FAVORITE_VIDEO_SUCCESS = 'ADD_THEN_UPDATE_FAVORITE_VIDEO_SUCCESS'
export const ADD_THEN_UPDATE_FAVORITE_VIDEO_FAILURE = 'ADD_THEN_UPDATE_FAVORITE_VIDEO_FAILURE'
export const ADD_THEN_UPDATE_FAVORITE_VIDEO = 'ADD_THEN_UPDATE_FAVORITE_VIDEO'
export const ADD_FAVORITE_VIDEO_ATTEMPT = 'ADD_FAVORITE_VIDEO_ATTEMPT'
export const UNFAVE_VIDEO = "UNFAVE_VIDEO"
export const UNFAVE_VIDEO_SUCCESS = "UNFAVE_VIDEO_SUCCESS"
export const UNFAVE_VIDEO_FAILURE = "UNFAVE_VIDEO_FAILURE"
export const UNFAVE_THEN_GET_FAVORITE_VIDEO = "UNFAVE_THEN_GET_FAVORITE_VIDEO"
export const UNFAVE_THEN_GET_FAVORITE_VIDEO_SUCCESS = "UNFAVE_THEN_GET_FAVORITE_VIDEO_SUCCESS"
export const UNFAVE_THEN_GET_FAVORITE_VIDEO_FAILURE = "UNFAVE_THEN_GET_FAVORITE_VIDEO_FAILURE"

export const getFavorites = function (headers) {
    return {
        type: GET_ALL_FAVORITES_VIDEO,
        payload: {
            headers
        }
    }
}

export const addFavoritesVideo = function (headers, videoId, callback) {
    return {
        type: ADD_FAVORITE_VIDEO,
        payload: {
            headers,
            videoId,
            callback
        }
    }
}

export const addFavoritesVideoAttempt = function (headers, videoId, callback) {
    return {
        type: ADD_FAVORITE_VIDEO_ATTEMPT,
        payload: {
            headers,
            videoId,
            callback
        }
    }
}

export const addThenUpdateFaveVid = function (headers, videoId, callback) {
    return {
        type: ADD_THEN_UPDATE_FAVORITE_VIDEO,
        payload: {
            headers,
            videoId,
            callback
        }
    }
}

export const unfavoritVideo = function (headers, instanceId, callback) {
    return {
        type: UNFAVE_VIDEO,
        payload: {
            headers,
            instanceId,
            callback
        }
    }
}

// changed the name from deleteThenUpdateFaveVid to deleteThenGetFaveVid
export const unfavoriteThenGetFaveVid = function (headers, instanceId, callback) {
    return {
        type: UNFAVE_THEN_GET_FAVORITE_VIDEO,
        payload: {
            headers,
            instanceId,
            callback
        }
    }

}