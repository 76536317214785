import React, { Component } from 'react';

class PrivacyPolicy extends Component {

  render() {
    return (
      <div>
        <div className="Background-color-nav" />
        <div className="PrivacyPolicy">

          <p>Privacy Policy</p>
          {' '}
          <br />
          <p>Updated February 1, 2021</p>
          <br />
          <br />
          <p>
            This Privacy Policy (this “Policy”) provides information about the information that JetSweat LLC (“JetSweat” or “We”) may collect from users of the mobile software application that JetSweat makes available for download (individually and collectively, the “App”), the related website located at www.jetsweatfitness.com and any other online properties owned or controlled by or on behalf of JetSweat (collectively with the App, the “Service”), and how we use and share such information. By using the Service, you agree to the terms of this Policy. We may change this Policy from time to time, and changes are effective upon posting. Please review this Policy regularly. Your continued use of the Service after changes are posted constitutes your agreement to the changes. If you do not agree to the terms of this Policy, please discontinue your use of the Service.
          </p>
          <br />
          <br />
          <p>1.Information Collection</p>
          <br />
          <p>Each time you provide or transmit information to us via the Service, JetSweat may obtain and collect personally identifiable information about you, including, but not limited to, your name, email address, and any other information that may be used to identify you.  For example, and without limitation, we may collect such information from you when you create a user account on the App or when you contact our staff.  We may also collect non-personally identifiable information from you.  Non-personally identifiable information is data that is not used to specifically identify, contact, or locate an individual, including but not limited to zip code, gender, age, or IP address.</p>
          <br />
          <p>We may use the information we collect from and about you for any of the following purposes:
            <ol style={{ paddingLeft: '30px', paddingTop: '15px' }}>
              <li>to fulfill your requests for classes or other products or services;</li>
              <li>to respond to your inquiries;</li>
              <li>to review Service usage and operations;</li>
              <li>to address problems with the Service, our business, or our services;</li>
              <li>to protect the security or integrity of the Service and our business;</li>
              <li>to monitor the Service for compliance with our End User License Agreement and Terms of Service (“Terms of Use”) and the law;</li>
              <li>to help improve our services; and</li>
              <li>to contact you with updates and other informational and promotional materials from us or third party marketing offers from our trusted partners, as well as from other companies.  In addition, JetSweat may partner with third party advertisers to help deliver advertisements through the Service relevant to your interests.</li>
            </ol>
          </p>
          <br />
          <br />

          <p>2.Information Use</p>
          <br />
          <p>We may use the information we collect from and about you for any of the following purposes: (1) to fulfill your requests for classes or other products or services; (2) to respond to your inquiries; (3) to review Service usage and operations; (4) to address problems with the Service, our business, or our services; (5) to protect the security or integrity of the Service and our business; (6) to monitor the Service for compliance with our End User License Agreement and Terms of Service (“Terms of Use”) and the law; (7) to help improve our services; and (8) to contact you with updates and other informational and promotional materials from us or third party marketing offers from our trusted partners, as well as from other companies.  In addition, JetSweat may partner with third party advertisers to help deliver advertisements through the Service relevant to your interests. </p>
          <br />
          <br />

          <p>3.Information Sharing</p>
          <br />
          <p>We may disclose information collected from and about you as follows: (1) to our related companies and service providers, to perform a business, professional or technical support function for us; (2) to our marketing partners, advertisers or other third parties, who may contact you with their own offers; (3) as necessary if we believe that there has been a violation of our Terms of Use or of our rights or the rights of any third party; (4) to respond to legal process (such as a search warrant, subpoena or court order) and provide information to law enforcement agencies or in connection with an investigation on matters related to public safety, as permitted by law, or otherwise as required by law; and (5) in the event that our company or substantially all of its assets are acquired, your personal information may be one of the transferred assets. We may also disclose your personal information with your express consent.  We may share aggregate, non-personally identifiable information about Service users with third parties. </p>
          <br />
          <br />

          <p>4.Interest-Based Advertising</p>
          <br />
          <p>We may partner with ad networks and other ad serving providers (“Advertising Providers”) who serve ads on behalf of us and others on non-affiliated websites or mobile applications. Some of those ads may be personalized, meaning that they are intended to be relevant to you based on information Advertising Providers collect about your visits to this site and elsewhere over time. </p>
          <br />
          <p>You may visit www.aboutads.info to learn more about this type of advertising and how to opt-out of this form of advertising by companies participating in the Digital Advertising Alliance (“DAA”) self-regulatory program.  Note that electing to opt-out will not stop advertising from appearing in your browser or applications.  It may make the ads you see less relevant to your interests.  In addition, note that if you use a different browser or erase cookies from your browser, you may need to renew your opt-out choice.</p>
          <br />
          <br />

          <p>5.Children</p>
          <br />
          <p>Our Service is not directed to children under 13. We will never knowingly collect any personally identifiable information about children under the age of 13. If JetSweat obtains actual knowledge that it has collected personal information about a child under the age of 13, that information will be immediately deleted and will not be shared or disclosed to third parties.</p>
          <br />
          <br />

          <p>6.Apple Health Information</p>
          <br />
          <p>In order to provide our services, such as recommending video content you may enjoy, we may read and/or write data to the Apple Health database on your Apple device with your consent. We will not use this information for marketing or advertising purposes or share it with third parties.</p>
          <br />
          <br />

          <p>7.California Privacy Rights</p>
          <br />
          <p>Under California's "Shine the Light" law, we provide a method for consumers to "opt-out" of having their information shared with third-parties. Contact us at support@jetsweatfitness.com to unsubscribe.</p>
          <br />
          <br />

          <p>8. Delete Your Information</p>
          <br />
          <p>At any time, you may request that we remove a posting made to our Services or deactivate your account, if you have an account with us, by contacting us at support@jetsweatfitness.com or writing to us at 16 Esterbrook Road, Wainscott, NY 11975. </p>
          <br />
          <br />


          <p>9.Security</p>
          <br />
          <p>We take appropriate commercially reasonable physical, electronic, and other security measures to help safeguard personal information from unauthorized access, alteration, or disclosure.  Even though we follow reasonable procedures to try to protect the information in our possession, no security system is perfect and we cannot promise, and you should not expect, that your information will be secure in all circumstances.</p>
          <br />
          <br />

          <p>10.CONTACT US</p>
          <br />
          <p>If you have any questions regarding this Policy, contact us at support@jetsweatfitness.com.</p>
          <br />
          <br />

        </div>
      </div>
    );
  }
}

export default PrivacyPolicy;
