import React, { Component } from "react";

import AccountNew from "./AccountNew";
import AccountNewWhiteLabel from "./AccountNewWhiteLabel";
//import "./account.css";
import Footer from "../footer.js";

class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      whiteLabel: this.props.currUser.accountType === "studio",
      isGympass: this.props.currUser.accessType === "gympass",
      date: window.location.pathname.split("/")[3],
      studio: window.location.pathname.split("/")[2]
    };
    this.handleCancellation = this.handleCancellation.bind(this);
  }

  handleCancellation(event) {
    fetch("/api/user/subscription", {
      method: "DELETE",
      headers: {
        "Content-type": "application/json",
        Authorization: this.props.currUser.authToken
      },
      body: JSON.stringify({})
    }).then((res) => {
      if (res.status >= 400) {
      } else {
        return res.json();
      }
    });
  }

  getProedgePixel() {
    if (this.state.whiteLabel) return null;
    return (
      <img
        src="https://sboffers.swagbucks.com/GL7Fx"
        alt="No Whitelabel"
        style={{
          width: "1px",
          height: "1px",
          zIndex: "-1",
          position: "absolute"
        }}
      />
    );
  }

  render() {
    let { whiteLabelBackgroundStyles } = this.props;
    let buttonStyles = {};
    const currentUser = this.props.currUser;

    if (this.props.isWhiteLabelDomain) {
      buttonStyles = {
        ...whiteLabelBackgroundStyles
      };
    }
    
    return (
      <div>
        {
          // this.getProedgePixel()
          this.state.whiteLabel ? (
            <AccountNewWhiteLabel
              {...this?.props}
              currentUser={currentUser}
              studio={this.props.studioName}
              buttonStyles={buttonStyles}
            />
          ) : (
            <AccountNew
              {...this?.props}
              currentUser={currentUser}
              isGympass={this.state.isGympass}
            />
          )
        }

        {this.state.whiteLabel ? null : <Footer />}
      </div>
    );
  }
}

export default Account;
/*
<div className="Background-color-nav" />
<div className="innerBox">
  <h2>Confirmed</h2>

  <div className="Center paymentdone">
    <img
      alt="tick"
      className="tick"
      src={require("../../images/tick.png")}
    />
    <p className="successfulpayment">
      {this.state.isGympass ? (
        <strong>Registration successful</strong>
      ) : (
        <strong>Payment has been made successfully.</strong>
      )}
    </p>
  </div>
 --------------- ---------------


  <div className="smallerwidth">
    <p className="confirmedcocolight">
      Please check your inbox at{" "}
      <span className="Numbers confirmednumbers">
        {this.props.currUser.email}
      </span>{" "}
      for your welcome email.
    </p>
    <br />
    {!this.state.date ? (
      <>
        <p className="successfulpayment">
          Get started in{" "}
          <span className="Numbers confirmednumbers">3</span> easy
          steps:
        </p>
        <br />
        <br />
      </>
    ) : null}
    {this.state.date ? wlDaypass : null}
    {this.state.whiteLabel ? (
      this.state.date ? null : (
        wl1
      )
    ) : (
      <>
        <p className="confirmedfont">
          <span className="Numbers confirmednumbers">1)</span>{" "}
        </p>
        <div>
          Download the app on the App Store:
          <div className="Center">
            <a href="https://itunes.apple.com/us/app/jetsweat/id1262223853?mt=8">
              <button className="confirmdownload">
                DOWNLOAD ON THE APP STORE
              </button>
            </a>
          </div>
        </div>
        <br />
        <div className="Center">
          <Link to="/newplatform/dashboard">
            <button className="toplatform">
              CONTINUE TO WEB PLATFORM
            </button>
          </Link>
        </div>
      </>
    )}
    {this.state.date ? null : (
      <>
        <p className="confirmedfont">
          <span className="Numbers confirmednumbers">2)</span>{" "}
          {this.state.whiteLabel ? null : "Log in and "}
          Complete your Fitness Profile
        </p>
        <p className="confirmedfont lastitem">
          <span className="Numbers confirmednumbers">3)</span> Workout
          now
          {this.state.whiteLabel
            ? " or schedule your workouts for later"
            : " on iPhone, Chromecast or Airplay, or explore workouts to schedule for later"}
        </p>
      </>
    )}

    {this.state.whiteLabel ? null : (
      <Link
        className="confirmedfont compatibledeviceslink"
        to="Compatibledevices"
      >
        Check our compatible viewing devices.
      </Link>
    )}
    <div className="orderId">Order: {this.props.currUser.id}</div>
  </div>
</div>
*/
