export const ActionTypes = {
  GET_STRIPE_API_KEY: 'GET_STRIPE_API_KEY',
  GET_STRIPE_API_KEY_SUCCESS: 'GET_STRIPE_API_KEY_SUCCESS',
  GET_STRIPE_API_KEY_FAILURE: 'GET_STRIPE_API_KEY_FAILURE',
  CANCEL_USER_STRIPE_SUBCRIPTION: "CANCEL_USER_STRIPE_SUBCRIPTION",
  CANCEL_USER_STRIPE_SUBCRIPTION_SUCCESS: "CANCEL_USER_STRIPE_SUBCRIPTION_SUCCESS",
  CANCEL_USER_STRIPE_SUBCRIPTION_FAILURE: "CANCEL_USER_STRIPE_SUBCRIPTION_FAILURE",
  PAUSE_USER_STRIPE_SUBSCRIPTION: "PAUSE_USER_STRIPE_SUBSCRIPTION",
  PAUSE_USER_STRIPE_SUBSCRIPTION_SUCCESS: "PAUSE_USER_STRIPE_SUBSCRIPTION_SUCCESS",
  PAUSE_USER_STRIPE_SUBSCRIPTION_FAILURE: "PAUSE_USER_STRIPE_SUBSCRIPTION_FAILURE",
  UNDO_PAUSE_USER_STRIPE_SUBSCRIPTION: "UNDO_PAUSE_USER_STRIPE_SUBSCRIPTION",
  UNDO_PAUSE_USER_STRIPE_SUBSCRIPTION_SUCCESS: "UNDO_PAUSE_USER_STRIPE_SUBSCRIPTION_SUCCESS",
  UNDO_PAUSE_USER_STRIPE_SUBSCRIPTION_FAILURE: "UNDO_PAUSE_USER_STRIPE_SUBSCRIPTION_FAILURE",
  UNDO_CANCEL_USER_STRIPE_SUBCRIPTION: "UNDO_CANCEL_USER_STRIPE_SUBCRIPTION",
  UNDO_CANCEL_USER_STRIPE_SUBCRIPTION_SUCCESS: "UNDO_CANCEL_USER_STRIPE_SUBCRIPTION_SUCCESS",
  UNDO_CANCEL_USER_STRIPE_SUBCRIPTION_FAILURE: "UNDO_CANCEL_USER_STRIPE_SUBCRIPTION_FAILURE"
}

export const getStripeApiKey = function (email) {
  return {
    type: ActionTypes.GET_STRIPE_API_KEY
  }
}

export const cancelUserStripeSubscription = function (userSubscriptionId, headers) {
  return {
    type: ActionTypes.CANCEL_USER_STRIPE_SUBCRIPTION,
    payload: {
      userSubscriptionId,
      headers
    },
  }
}

export const pauseUserStripeSubscription = function (userSubscriptionId, headers) {
  return {
    type: ActionTypes.PAUSE_USER_STRIPE_SUBSCRIPTION,
    payload: {
      userSubscriptionId,
      headers
    },
  }
}

export const undoPauseUserStripeSubscription = function (userSubscriptionId, headers) {
  return {
    type: ActionTypes.UNDO_PAUSE_USER_STRIPE_SUBSCRIPTION,
    payload: {
      userSubscriptionId,
      headers
    },
  }
}

export const undoCancelUserStripeSUbscription = function (userSubscriptionId, headers) {
  return {
    type: ActionTypes.UNDO_CANCEL_USER_STRIPE_SUBCRIPTION,
    payload: {
      userSubscriptionId,
      headers
    },
  }
}
