import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import SingleStudioView from "./SingleStudioView";
import { IMAGES } from "../../../src";

const SingleStudioContainer = (props) => {
  let studioId;
  const [studios, setStudios] = useState([]);
  if (props.studioId === undefined) {
    const studioFromUrl = props.match.params.studioName;
    studioId = studioFromUrl;
  } else {
    studioId = props.studioId;
  }

  useEffect(() => {
    const fetchStudios = () => {
      fetch("/api/basic/webStudiosInfo", {
        method: "GET",
      })
        .then((res) => res.json())
        .then((res) => {
          setStudios([...res.content]);
        });
    };
    fetchStudios();
  }, []);

  return (
    <SingleStudioView
      studioInfo={studios}
      key={studioId}
      studioId={studioId}
      currentStudio={props.currentStudio}
      isLoggedIn={props.isLoggedIn}
      compatibleDevices={IMAGES.compatibleDevices}
      icons={IMAGES.icons}
      restrictView={props.restrictView}
    />
  );
};
export default withRouter(SingleStudioContainer);
