import React from "react";
import { connect } from "react-redux";
import JetsweatModal from "../JetsweatModal";
import { days, months } from "../VideoFilters/constants";
import authFetch from "../../authFetch/authFetch";

class OrderSummaryModal extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    try {
      authFetch("api/user/subscription", {
        headers: { Authorization: this.props.currUser.authToken },
      }).then((body) => {
        if (!body.status) {
          throw new Error("API Error");
        }
        this.props.currUser.subscription = body.content;
      });
    } catch (err) {
      console.log(err);
    }
  }

  render() {
    const { selectedClass, whiteLabelBackgroundStyles } = this.props;
    const date = new Date(this.props.selectedClass.scheduledDatetime);
    const militaryHour = date.getHours();
    var hours = militaryHour > 12 ? militaryHour % 12 : militaryHour;
    const mins =
      date.getMinutes() < 10 ? `${date.getMinutes()}0` : date.getMinutes();
    const period = militaryHour < 12 ? "AM" : "PM";
    if (hours === 0) {
      hours = 12;
    }
    return (
      <JetsweatModal
        showModal={this.props.showModal}
        closeModal={this.props.closeModal}
        title={this.props.title}
        whiteLabelBackgroundStyles={this.props.whiteLabelBackgroundStyles}
        preventCloseModal={this.props.preventCloseModal}
        customStyles={this.props.customStyles}
      >
        <div className="order-summary-text">Order Summary</div>
        <div className="order-summary-details">
          A confirmation email with login instructions has been sent to your
          email address.
        </div>

        <div className="payment-columns-container row">
          <div className="payment-columns-1 col-5">
            <h3>Date</h3>
          </div>
          <div className="payment-columns-2 col-7">
            <h3>
              {days[date.getDay()]}, {months[date.getMonth()]} {date.getDate()},{" "}
              {date.getFullYear()}
            </h3>
          </div>
          <div className="payment-columns-1 col-5">
            <h3>Time</h3>
          </div>
          <div className="payment-columns-2 col-7">
            <h3>{`${hours}:${mins}${period}`}</h3>
          </div>
          <div className="payment-columns-1 col-5">
            <h3>Class</h3>
          </div>
          <div className="payment-columns-2 col-7">
            <h3>{selectedClass.title}</h3>
          </div>
          <div className="payment-columns-1 col-5">
            <h3>Amount Due</h3>
          </div>
          <div className="payment-columns-2 col-7">
            <h3>
              {this.props.showNewAmount === 0 ? (
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      textDecoration: "line-through",
                      paddingRight: "5px",
                    }}
                  >
                    ${selectedClass.price}
                  </div>
                  <div style={{ color: "#89CFBA" }}>FREE</div>
                </div>
              ) : (
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      textDecoration: "line-through",
                      paddingRight: "5px",
                    }}
                  >
                    ${selectedClass.price}
                  </div>
                  <div style={{ color: "#89CFBA" }}>
                    ${this.props.showNewAmount}
                  </div>
                </div>
              )}
            </h3>
          </div>
        </div>
        <hr />
        <div
          className="confirm-button"
          onClick={this.props.closeModal}
          style={{
            backgroundColor: whiteLabelBackgroundStyles
              ? whiteLabelBackgroundStyles.backgroundColor
              : "#9ac8b9",
          }}
        >
          Okay
        </div>
      </JetsweatModal>
    );
  }
}

const mSTP = (state) => {
  return {
    coupon: state.basic.coupon,
    success: state.basic.success,
  };
};

export default connect(mSTP)(OrderSummaryModal);
