import * as APIUtil from "../../actions";
import { syncQueueGoogle, syncQueueOutlook } from "../store/calendarHelpers";

const moment = require("moment");

export const fetchLiveVideosJetsweat = (user) => async (dispatch) => {
  try {
    let response = await fetch("/api/liveclass/upcoming", {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: user.authToken,
      },
    });
    let data = await response.json();
    data = data.content;
    const action = APIUtil.getLiveJetsweat(data);
    dispatch(action);
  } catch (error) {
    console.log(error);
  }
};
export const fetchLiveVideosJetsweatPublic = (id) => async (dispatch) => {
  try {
    let response = await fetch(
      `/api/liveclass/upcoming/public/all/${id || ""}`,
      {
        method: "GET",
        headers: {
          "Content-type": "application/json",
        },
      }
    );
    let data = await response.json();
    data = data.content;
    const action = APIUtil.getLiveJetsweat(data);
    dispatch(action);
  } catch (error) {
    console.log(error);
  }
};
export const fetchLiveDetails = (user, id) => async (dispatch) => {
  try {
    let response = await fetch(`/api/liveclass/upcoming/${id}`, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: user.authToken,
      },
    });
    
    if (response.status >= 400) {
      let liveVideoErrorJson = null;
      if (response?.status === 402) {
        liveVideoErrorJson = await response.json();
      }
      const action = APIUtil.getLiveClassDetailsFailure(null, liveVideoErrorJson);
      dispatch(action);
    } else {
      let data = await response.json();
      data = data.content;
      const action = APIUtil.getLiveClassDetails(data);
      dispatch(action);
    }
  } catch (error) {
    const action = APIUtil.getLiveClassDetailsFailure();
    dispatch(action);
  }
};

export const fetchLiveContentId = (user, id) => async (dispatch) => {
  try {
    let response = await fetch(`/api/livestream/channel/channelId/${id}`, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: user.authToken,
      },
    });
    let data = await response.json();
    data = data.content;
    const action = APIUtil.getContentId(data);
    dispatch(action);
  } catch (error) {
    console.log(error);
  }
};

export const scheduleLiveClass = (user, id, time) => async (
  dispatch,
  getState
) => {
  const { GoogleAuth } = getState().calendarReducer;
  try {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const dateObj = new Date(time);
    const when = moment(dateObj).utc().startOf('minute').utc().toISOString()
    let response = await fetch("/api/workout/add", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: user.authToken,
      },
      body: JSON.stringify({
        authUserInfo: user,
        videoId: null,
        liveClassId: id,
        status: "scheduled",
        when: when,
      }),
    }).then(async () => {
      if (GoogleAuth && GoogleAuth.isSignedIn.get()) {
        await syncQueueGoogle(null, null, user);
      }
      if (sessionStorage.getItem("graphUser")) {
        await syncQueueOutlook(null, null, user);
      }
    });
    let data = await response.json();
    data = data.content;
    const action = APIUtil.getContentId(data);
    dispatch(action);
    return Promise.resolve(true);
  } catch (error) {
    console.log(error);
    return Promise.reject(false);
  }
};

export default function liveReducer(state = {}, action) {
  switch (action.type) {
    case "GET_ALL_LIVE_VIDEOS_JETSWEAT":
      return {
        ...state,
        videos: action.liveVideos,
      };
    case "GET_LIVE_CLASS_DESCRIPTION":
      return {
        ...state,
        success: true,
        liveVideo: action.liveVideo,
        liveVideoErrorJson: null,
      };
    case "GET_LIVE_CLASS_DESCRIPTION_FAILURE":
      return {
        ...state,
        success: false,
        liveVideoErrorJson: action.liveVideoErrorJson,
      };
    case "GET_LIVE_CONTENT_ID":
      return {
        ...state,
        contentId: action.id.channelId,
      };
    case "RECEIVE_SCHEDULED_LIVE":
      return {
        ...state,
        liveClasses: action.liveClasses,
      };
    default:
      return state;
  }
}
