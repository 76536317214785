import { graphClient } from "./calendarReducer";

const moment = require("moment");

/////////   GOOGLE  ///////////////

export const signInChangedGoogle = async (val, currUser) => {
  if (val) {
    await syncQueueGoogle(null, null, currUser);
  }
};

const listGoogleEvents = async (token) => {
  return await window.gapi.client.calendar.events.list({
    calendarId: "primary",
    timeMin: new Date().toISOString(),
    showDeleted: false,
    pageToken: token,
  });
};
const upcomingGoogleEvents = async () => {
  let upcomingEvents = [];
  let token = null;
  while (true) {
    let res = await listGoogleEvents(token);
    let { nextPageToken } = res.result;
    upcomingEvents.push(...res.result.items);

    if (!nextPageToken) {
      break;
    } else {
      token = nextPageToken;
    }
  }

  return upcomingEvents;
};
const fetchFutureWorkouts = async (currUser) => {
  let d = new Date();
  let year = d.getFullYear();
  let month = d.getMonth();
  let day = d.getDay();
  var c = new Date(year + 1, month, day);
  const res = await fetch("/api/workout/myWorkout", {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Authorization: currUser.authToken,
    },
    body: JSON.stringify({
      fromDate: d.toLocaleDateString("en-CA"),
      toDate: c.toLocaleDateString("en-CA"),
      // when: new Date(Date.now()),
      authUserInfo: { id: currUser.id },
    }),
  });
  if (res.ok) {
    let data = await res.json();
    let { workouts } = data.content;
    if (workouts.length) return workouts;
  }
  return null;
};

export const syncQueueGoogle = async (
  deletedWorkout,
  turnOff,
  currUser,
  updatedWorkout,
  whiteLabelDomain
) => {
  if (!localStorage.getItem("signingOut")) {
    let queueStr =
      localStorage.getItem("syncQueueGoogle") ||
      localStorage.setItem("syncQueueGoogle", JSON.stringify([]));
    let syncQueue;
    if (queueStr) {
      syncQueue = JSON.parse(queueStr);
    } else {
      syncQueue = [];
    }
    let map = {};
    for (let i = 0; i < syncQueue.length; i++) {
      map[syncQueue[i].eventId] = true;
    }

    let workouts = await fetchFutureWorkouts(currUser);
    let upcomingEvents = await upcomingGoogleEvents();
    upcomingEvents = upcomingEvents.filter((e) => {
      if (
        e.location === "Jetsweatfitness.com" ||
        (whiteLabelDomain && e.location === whiteLabelDomain)
      ) {
        map[e.extendedProperties.private.jetsweatId] = e.id;
        if (turnOff && !map[e.id]) {
          syncQueue.push({
            method: "DELETE",
            calendarId: "primary",
            eventId: e.id,
          });
        }
        return true;
      }
    });

    if (deletedWorkout && map[deletedWorkout.id]) {
      syncQueue.push({
        method: "DELETE",
        eventId: map[deletedWorkout.id],
        calendarId: "primary",
      });
    } else if (updatedWorkout && map[updatedWorkout.id]) {
      let duration = updatedWorkout.duration;
      let startTime = new Date(updatedWorkout.when);
      let endTime = moment(startTime).add(duration, "seconds");
      const updatedTime = {
        start: {
          dateTime: new Date(startTime).toISOString(),
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        end: {
          dateTime: new Date(endTime._d).toISOString(),
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      };

      syncQueue.push({
        method: "PUT",
        resource: updatedTime,
        eventId: map[updatedWorkout.id],
        calendarId: "primary",
      });
    } else if (workouts && !turnOff) {
      for (let i = 0; i < workouts.length; i++) {
        let workout = workouts[i];
        let duration =
          (workout.video && workout.video.duration) ||
          (workout.liveClass && workout.liveClass.minutesDuration * 60);
        let startTime = new Date(workout.when);
        let endTime = moment(startTime).add(duration, "seconds");

        if (
          !map[workout.id] &&
          (workout.status === "scheduled" || workout.status === null)
        ) {
          var event = {
            summary:
              (workout.video && workout.video.title) ||
              (workout.liveClass && workout.liveClass.title),
            location: whiteLabelDomain || "Jetsweatfitness.com",
            description:
              (workout.video && workout.video.summary) ||
              (workout.liveClass && workout.liveClass.description),
            start: {
              dateTime: startTime,
            },
            end: {
              dateTime: endTime._d,
            },
            extendedProperties: {
              private: {
                jetsweatId: workout.id,
              },
            },
          };
          syncQueue.push({
            method: "POST",
            calendarId: "primary",
            resource: event,
            eventId: workout.id,
          });
        }
      }
    }

    localStorage.setItem("syncQueueGoogle", JSON.stringify(syncQueue));
  }
};

/////////   OUTLOOK/MICROSOFT  ///////////////

export const getMicrosoftToken = async (msalClient) => {
  let account = sessionStorage.getItem("msalAccount");
  if (!account) {
    throw new Error(
      "User account missing from session. Please sign out and sign in again."
    );
  }
  const msalRequest = {
    scopes: ["user.read", "mailboxsettings.read", "calendars.readwrite"],
  };

  try {
    // First, attempt to get the token silently
    const silentRequest = {
      scopes: msalRequest.scopes,
      account: msalClient.getAccountByUsername(account),
    };
    const silentResult = await msalClient.acquireTokenSilent(silentRequest);
    console.log(silentRequest);
    return silentResult.accessToken;
  } catch (silentError) {
    // If silent requests fails with InteractionRequiredAuthError,
    // attempt to get the token interactively
    console.log(msalClient);
    if (silentError instanceof window.msal.InteractionRequiredAuthError) {
      const interactiveResult = await msalClient.acquireTokenPopup(msalRequest);
      console.log(interactiveResult);
      return interactiveResult.accessToken;
    } else {
      throw silentError;
    }
  }
};

const listOutlookEvents = async (urlToken) => {
  return await graphClient.api(urlToken).get();
};

const upcomingOutlookEvents = async () => {
  let upcomingEvents = [];
  let token = "/me/calendar/events";
  while (true) {
    let res = await listOutlookEvents(token);
    let nextPageToken = res["@odata.nextLink"];
    upcomingEvents.push(...res.value);

    if (!nextPageToken) {
      break;
    } else {
      token = nextPageToken.slice(nextPageToken.indexOf("me") - 1);
    }
  }

  return upcomingEvents;
};

export const syncQueueOutlook = async (
  deletedWorkout,
  turnOff,
  currUser,
  updatedWorkout,
  whiteLabelDomain
) => {
  if (!localStorage.getItem("signingOut")) {
    let queueStr =
      localStorage.getItem("syncQueueOutlook") ||
      localStorage.setItem("syncQueueOutlook", JSON.stringify([]));

    let syncQueue;

    if (queueStr) {
      syncQueue = JSON.parse(queueStr);
    } else {
      syncQueue = [];
    }

    let map = {};

    for (let i = 0; i < syncQueue.length; i++) {
      map[syncQueue[i].eventId] = true;
    }

    let workouts = await fetchFutureWorkouts(currUser);
    let upcomingEvents = await upcomingOutlookEvents();

    upcomingEvents = upcomingEvents.filter((e) => {
      if (
        e.location.displayName === "Jetsweatfitness.com" ||
        (whiteLabelDomain && e.location.displayName === whiteLabelDomain)
      ) {
        map[e.transactionId] = e.id;
        if (turnOff) {
          syncQueue.push({
            method: "DELETE",
            eventId: e.id,
          });
        }
        return true;
      }
    });

    if (deletedWorkout && map[String(deletedWorkout.id)]) {
      syncQueue.push({
        method: "DELETE",
        eventId: map[String(deletedWorkout.id)],
      });
    } else if (updatedWorkout && map[String(updatedWorkout.id)]) {
      let duration = updatedWorkout.duration;
      let startTime = new Date(updatedWorkout.when);
      let endTime = moment(startTime).add(duration, "seconds");
      const updatedTime = {
        start: {
          dateTime: new Date(startTime).toISOString(),
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        end: {
          dateTime: new Date(endTime._d).toISOString(),
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      };

      syncQueue.push({
        method: "PUT",
        event: updatedTime,
        eventId: map[String(updatedWorkout.id)],
      });
    } else if (workouts && !turnOff) {
      for (let i = 0; i < workouts.length; i++) {
        let workout = workouts[i];
        let duration =
          (workout.video && workout.video.duration) ||
          (workout.liveClass && workout.liveClass.minutesDuration * 60);
        let startTime = new Date(workout.when);
        let endTime = moment(startTime).add(duration, "seconds");

        if (
          !map[String(workout.id)] &&
          (workout.status === "scheduled" || workout.status === null)
        ) {
          const event = {
            subject:
              (workout.video && workout.video.title) ||
              (workout.liveClass && workout.liveClass.title),
            body: {
              contentType: "HTML",
              content:
                (workout.video && workout.video.summary) ||
                (workout.liveClass && workout.liveClass.description),
            },
            start: {
              dateTime: new Date(startTime).toISOString(),
              timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
            end: {
              dateTime: new Date(endTime._d).toISOString(),
              timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
            location: {
              displayName: whiteLabelDomain || "Jetsweatfitness.com",
            },

            allowNewTimeProposals: true,
            transactionId: String(workout.id),
          };

          syncQueue.push({
            method: "POST",
            event,
          });
        }
      }
    }

    localStorage.setItem("syncQueueOutlook", JSON.stringify(syncQueue));
  }
};
