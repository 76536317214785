export const ActionTypes = {
    GET_ALL_FAVE_STUDIOS: "GET_ALL_FAVE_STUDIOS",
    GET_ALL_FAVE_STUDIOS_SUCCESS: "GET_ALL_FAVE_STUDIOS_SUCCESS",
    GET_ALL_FAVE_STUDIOS_FAILURE: "GET_ALL_FAVE_STUDIOS_FAILURE",
    ADD_FAVORITE_STUDIOS: "ADD_FAVORITE_STUDIOS",
    ADD_FAVORITE_STUDIOS_FAILURE: "ADD_FAVORITE_STUDIOS_FAILURE",
    ADD_FAVORITE_STUDIOS_SUCCESS: "ADD_FAVORITE_STUDIOS_SUCCESS",
    UNFAVORITE_STUDIOS: "UNFAVORITE_STUDIOS",
    UNFAVORITE_STUDIOS_FAILURE: "UNFAVORITE_STUDIOS_FAILURE",
    UNFAVORITE_STUDIOS_SUCCESS: "UNFAVORITE_STUDIOS_SUCCESS",
}

export const getFaveStudios = function (headers) {
    return {
        type: ActionTypes.GET_ALL_FAVE_STUDIOS,
        payload: {
            headers
        }
    }
}

export const addFaveStudios = function (headers, studioId, callback) {
    return {
        type: ActionTypes.ADD_FAVORITE_STUDIOS,
        payload: {
            headers, 
            studioId,
            callback
        }
    }
}

export const unfavoriteStudios = function (headers, instanceId, callback) {
    return {
        type: ActionTypes.UNFAVORITE_STUDIOS,
        payload: {
            headers,
            instanceId,
            callback
        }
    }
}