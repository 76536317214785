import { ActionTypes } from "../../actions/profile.actions.js";

const initialState = {
    success: null,
    userAge: null,
    userWeight: null,
    userGender: null,
    error: {}
};

const profileReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.GET_ALL_PROFILE_DATA:
            return { ...state, success: null };
        case ActionTypes.GET_ALL_PROFILE_DATA_SUCCESS:
            return {
                ...state,
                success: true,
                userAge: 35,
                userWeight: 143,
                userGender: "F",
            };
        case ActionTypes.GET_ALL_PROFILE_DATA_FAILURE:
            return { ...state, success: false };

        case ActionTypes.SAVE_PROFILE_DATA:
            return { ...state, success: null }

        case ActionTypes.SAVE_PROFILE_DATA_SUCCESS:
            return { ...state, success: true }

        case ActionTypes.SAVE_PROFILE_DATA_FAILURE:
            return { ...state, success: false, error: action.error }
        default:
            return state;
    }


};

export default profileReducer;