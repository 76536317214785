import { put, call, all, takeLatest, takeEvery } from "redux-saga/effects";
import { ActionTypes } from "redux/actions/antmedia.actions";
import { post } from '../../api'

const BASE_URL = `${process.env.REACT_APP_API_ANTMEDIA}`

export const createBroadcast = function* (action) {

    const { streamId, liveClassId } = action.payload;

    try {
        const response = yield fetch(BASE_URL + "/rest/v2/broadcasts/create", {
            method: "POST",
            headers: {
                "Content-type": "application/json",
                Authorization: `${process.env.REACT_APP_API_ANTMEDIA_JWT}`
            },
            body: JSON.stringify({
                streamId: streamId,
            }),

        });

        const data = yield response.json()

        if (
            response.status >= 200 && response.status < 300
        ) {
            yield put({
                type: ActionTypes.CREATE_BROADCAST_SUCCESS,
                streamId: streamId,
                liveClassId: liveClassId
            })
        } else {
            throw data
        }


    } catch (err) {
        yield put({
            type: ActionTypes.CREATE_BROADCAST_FAILURE,
            streamId: streamId,
            error: err
        })
    }


};


export const deleteBroadcast = function* (action) {
    const { streamId } = action.payload

    try {
        const response = yield fetch(BASE_URL + "/rest/v2/broadcasts/" + streamId, {
            method: "DELETE",
            headers: {
                "Content-type": "application/json",
                Authorization: `${process.env.REACT_APP_API_ANTMEDIA_JWT}`
            }
        });

        const data = yield response.json()

        if (
            response.status >= 200 && response.status < 300
        ) {
            yield put({
                type: ActionTypes.DELETE_BROADCAST_SUCCESS,
                streamId: streamId
            })
        } else {
            throw data
        }

    } catch (err) {
        yield put({
            type: ActionTypes.DELETE_BROADCAST_FAILURE,
            streamId: streamId,
            error:
                err

        })
    }

}

export const deleteThenCreateBroadcast = function* (action) {
    try {
        yield call(deleteBroadcast, { payload: action.payload })
        yield call(createBroadcast, { payload: action.payload })
        yield put({
            type: ActionTypes.DELETE_THEN_CREATE_BROADCAST_SUCCESS
        })

    } catch (err) {
        yield put({
            type: ActionTypes.DELETE_THEN_CREATE_BROADCAST_FAILURE
        })
    }
}

function* antmedia() {
    yield all([takeEvery(ActionTypes.CREATE_BROADCAST, createBroadcast),
    takeEvery(ActionTypes.DELETE_BROADCAST, deleteBroadcast),
    takeLatest(ActionTypes.DELETE_THEN_CREATE_BROADCAST, deleteThenCreateBroadcast)
    ])
}

export default antmedia;