import { ActionTypes } from 'redux/actions/chat.actions.js'
import Placeholder from 'views/New_Platform/Live/chat/placeholder.png';
const {
  ADD_MESSAGE,
  ADD_MESSAGES,
  REFRESH_MESSAGES,
  INITIALIZE_MESSAGES
} = ActionTypes;

const initialState = {
  messages: [
    /*{
      userId: 1,
      imgSrc: Placeholder,
      type: 'Instructor',
      name: 'Esther',
      message: "Hey! After class, I’d love to chat with you all."
    },
    {
      userId: 1,
      imgSrc: Placeholder,
      type: 'Participant',
      name: 'Jamie',
      message: "Hello Class!"
    },
    {
      userId: 1,
      imgSrc: Placeholder,
      type: 'Friend',
      name: 'Esther',
      message: "Ready to start!!"
    }*/
  ],
}


export default function chatReducer(state = initialState, action) {
  switch (action.type) {
    case INITIALIZE_MESSAGES:

      let initialMessages = [];
      action.payload.messages.map((item) => {
        initialMessages.unshift(JSON.parse(item))
      })


      return {
        ...state,
        messages: [...initialMessages]
      }
    case ADD_MESSAGE:
      let newMessages1 = [...state.messages];
      newMessages1.unshift(action.payload.message)

      return {
        ...state,
        messages: [...newMessages1]
      }
    case ADD_MESSAGES:
      let newMessages2 = [...state.messages];
      action.payload.messages.map((item) => {
        newMessages2.unshift(JSON.parse(item))
      })

      return {
        ...state,
        messages: [...newMessages2]
      }
    case REFRESH_MESSAGES:
      let newMessages3 = [];
      action.payload.messages.map((item) => {
        newMessages3.unshift(JSON.parse(item))
      })

      return {
        ...state,
        messages: [...newMessages3]
      }
    default:
      return state
  }
}
