import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { verifyPromo } from "../redux/actions/basic.actions";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import "./cardsection.css";
import redError from "../images/icons/red-x.png";
import stripeLogo from "../images/icons/stripe-black.png";
import stripeLock from "../images/icons/stripe-lock.png";

const CardSection = (props) => {
  const [showInLive, setShowInLive] = useState(false);
  const [showPromo, setShowPromo] = useState(false);
  const [promo, setPromo] = useState("");
  const [response, setResponse] = useState("");
  const [isApplied, setIsApplied] = useState(false);
  const [goodResponse, setGoodResponse] = useState("");
  const [redAlert, setRedAlert] = useState();
  const [turnOn, setTurnOn] = useState(false);
  const [notFree, setNotFree] = useState(true);
  const [showPromoText, setShowPromoText] = useState(false)

  useEffect(() => {
    if (props.title == "Confirm live class booking") {
        setShowInLive(true);
    }

    
    setShowPromoText(true)
  }, []);

  useEffect(() => {
    if (props.success) {
      if (props.coupon.content.newAmount === 0) {
        setNotFree(false);
      }

      props.getOrderAmount(props.coupon.content.newAmount)
    }


  }, [props.success]);

  const addPromo = () => {
    setShowPromo(!showPromo);
  };

  const getPromo = async (code, price) => {
    const { verifyPromo } = props;

    await verifyPromo(code, price, props.authToken);
    if(showPromoText){
    props.promoText(code);
  }
  };

  useEffect(() => {
    if (props.coupon.hasOwnProperty("status")) {
      if (props.coupon.status === true) {
        setResponse(props.coupon.content.output);
        setIsApplied(true);
        setGoodResponse(true);
        setRedAlert(false);
      } else {
        if (turnOn) {
          setResponse(props.coupon.content);
          setIsApplied(true);
          setGoodResponse(false);
          setRedAlert(true);
        }
      }
    }
  }, [props.coupon]);

  useEffect(() => {
    getPromo();
  }, [props.showModal]);

  const addCode = () => {
    setTurnOn(true);
    getPromo(promo, props.selectedClass.price);
  };

  const removeCode = (code) => {
    setPromo("");
    setTurnOn(false);
    getPromo();
    setIsApplied(false);
    setNotFree(true);
  };

  const getCreditInfo = () => {
    return (
      <div>
        <div>
          <label className="center-thetext margin-top containerwidth">
            Credit or Debit Card
            <CardNumberElement
              className="margin input-card containerwidth"
              style={{
                base: {
                  iconColor: "#9ac8b9",
                  padding: "10px",
                  opacity: "1",
                  fontFamily: "coco-gothic-regular, sans-serif",
                  fontSize: "20px",
                  fontSmoothing: "antialiased",
                  ":-webkit-autofill": { color: "#9ac8b9" },
                  "::placeholder": { color: "#808080" },
                  invalid: {
                    conColor: "#FFC7EE",
                    color: "#FFC7EE",
                  },
                },
              }}
            />
          </label>
        </div>
        <div>
          <label className="center-thetext"> Expiration Date</label>
          <CardExpiryElement
            className="margin input-card input-width"
            style={{
              base: {
                iconColor: "#9ac8b9",
                padding: "10px",
                opacity: "1",
                fontFamily: "coco-gothic-regular, sans-serif",
                fontSize: "20px",
                fontSmoothing: "antialiased",
                ":-webkit-autofill": { color: "#9ac8b9" },
                "::placeholder": { color: "#808080" },
                invalid: {
                  conColor: "#FFC7EE",
                  color: "#FFC7EE",
                },
              },
            }}
          />
        </div>
        <label className="center-thetext"> CVC </label>
        <CardCvcElement
          className="margin  input-card input-width"
          style={{
            base: {
              iconColor: "#9ac8b9",
              padding: "10px",
              opacity: "1",
              fontFamily: "coco-gothic-regular, sans-serif",
              fontSize: "20px",
              fontSmoothing: "antialiased",
              ":-webkit-autofill": { color: "#9ac8b9" },
              "::placeholder": { color: "#808080" },
              invalid: {
                conColor: "#FFC7EE",
                color: "#FFC7EE",
              },
            },
          }}
        />
      </div>
    );
  };
  return (
    <div style={{ textAlign: "center" }}>
      {showInLive ? (
        <div>
          {props.success === true && (
            <>
              {props.coupon.content.newAmount === 0 && (
                <div style={{ fontSize: "30px", padding: "1rem" }}>
                  Sweet, this class is on us!
                </div>
              )}
            </>
          )}
          {notFree && <div>{getCreditInfo()}</div>}
        </div>
      ) : (
        ""
      )}

      {!showInLive ? (
        <div>{getCreditInfo()}</div>
      ) : (
        <div>
          {!showPromo && (
            <label
              className="center-thetext"
              style={{ textDecoration: "underline" }}
              onClick={() => addPromo()}
            >
              I have a promo code
            </label>
          )}
          {showPromo && (
              <div className="apply-promo-container" >
              <div>
                {isApplied ? (
                  <div id="input_container">
                    <input
                      className={`IO No-highlight-border field ${
                        redAlert ? "incorrect" : ""
                      }
                  }`}
                      type="text"
                      name="promo"
                      value={promo}
                      placeholder="Promo Code"
                      onChange={(e) => setPromo(e.target.value)}
                    />
                    {redAlert && (
                      <img
                        onClick={() => removeCode("")}
                        src={redError}
                        id="input_img"
                      />
                    )}
                  </div>
                ) : (
                  <div id="input_container">
                    <input
                      className={`IO No-highlight-border field
                }`}
                      type="text"
                      name="promo"
                      value={promo}
                      placeholder="Promo Code"
                      onChange={(e) => setPromo(e.target.value)}
                    />
                  </div>
                )}
              </div>
              <button
                  className="Button-style No-highlight-border Font verify apply-promo-bttn"
                type="button"
                style={{ backgroundColor: props.whiteLabelBackgroundStyles.backgroundColor || "#9ac8b9",}}
                onClick={() => addCode()}
              >
                Apply
              </button>
            </div>
          )}
          {isApplied && (
            <div>
              {goodResponse ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div className="IO good-response">Coupon Applied!</div>{" "}
                  <div
                    style={{
                      textDecoration: "underline",
                      fontSize: "12px",
                      cursor: "pointer",
                      paddingLeft: "5px",
                    }}
                    onClick={() => removeCode("")}
                  >
                    {" "}
                    REMOVE
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div className="IO bad-response">{response}</div>{" "}
                </div>
              )}
            </div>
            
          )}
           <div style={{ display: "flex", justifyContent: "center" }}>
              <img src={stripeLock} alt="stripe-lock" id="stripe_img" />
              <img src={stripeLogo} alt="stripe-logo" />
            </div>
        </div>
      )}
     
    </div>
  );
};

const mSTP = (state) => {
  return {
    coupon: state.basic.coupon,
    success: state.basic.success,
  };
};

const mDTP = (dispatcher) =>
  bindActionCreators(
    {
      verifyPromo,
    },
    dispatcher
  );

export default connect(mSTP, mDTP)(CardSection);
