import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Calendar } from "react-calendar";
import "./Calendar.css";
import Slider from "react-slick";
import SideBarItemContainer from "../New_Platform/SidebarItemContainer";
import CustomButton from "./CustomButton";
import ScheduleContainer from "./ScheduleContainer";
import WorkoutSlider from "./WorkoutSlider";
import * as CalendarUtil from "./CalendarUtil";
import { connect } from "react-redux";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { syncQueueGoogle, syncQueueOutlook } from "../store/calendarHelpers";
import { signingOut, signingIn, graphClient } from "../store/calendarReducer";
import { getPrograms } from "redux/actions/video.actions";

const moment = require("moment");

const options = [
  { value: "Google Calendar", label: "Google Calendar" },
  { value: "Outlook", label: "Outlook" },
  { value: "iCalendar", label: "iCalendar (Coming Soon)" },
];

class WorkoutCalendar extends Component {
  constructor(props) {
    console.log(props)
    super(props);
    this.state = {
      date: this.props.calendarDefaultDate,
      workouts: [],
      workoutsInCurrentMonth: [],
      start: moment().add(1, "M"),

      dropdownRef: React.createRef(),
    };
    this.buttons = [];
    this.onChange = this.onChange.bind(this);
    this.onActiveDateChange = this.onActiveDateChange.bind(this);
    this.onScheduleChange = this.onScheduleChange.bind(this);
    this.searchOnDemandVideos = this.searchOnDemandVideos.bind(this);
  }

  onChange(date) {
    this.setState({ date });
  }
  componentDidUpdate(prevProps, prevState) { }

  onActiveDateChange({ activeStartDate, view }) {
    const inRange = CalendarUtil.workoutsInRange(
      this.state.workouts,
      activeStartDate
    );
    this.setState({ workoutsInCurrentMonth: inRange });
  }

  getGraphUser = async () => {
    // const { graphClient } = this.props.calendar
    return await graphClient
      .api("/me")
      .select("id,displayName,mail,userPrincipalName,mailboxSettings")
      .get();
  };

  sync = async (calendar) => {
    const { user, studio } = this.props;
    const { GoogleAuth, OutlookAuth } = this.props.calendar;
    const msalRequest = {
      scopes: ["user.read", "mailboxsettings.read", "calendars.readwrite"],
    };
    switch (calendar) {
      case "Google Calendar":
        try {
          if (GoogleAuth && GoogleAuth.isSignedIn.get()) {
            await syncQueueGoogle(
              null,
              true,
              user,
              null,
              studio.whitelabelDomain
            );
            this.props.signingOut("GoogleAuth");
          } else {
            await GoogleAuth.signIn();
            this.props.signingIn("GoogleAuth");
          }
        } catch (e) {
          console.log(e);
        }
        break;
      case "Outlook":
        try {
          if (sessionStorage.getItem("graphUser")) {
            await syncQueueOutlook(
              null,
              true,
              user,
              null,
              studio.whiteLabelDomain
            );
            this.props.signingOut("OutlookAuth");
          } else {
            const authResult = await OutlookAuth.loginPopup(msalRequest);
            sessionStorage.setItem("msalAccount", authResult.account.username);

            const graphUser = await this.getGraphUser();
            sessionStorage.setItem("graphUser", JSON.stringify(graphUser));
            this.props.signingIn("OutlookAuth", user);
          }
        } catch (e) {
          console.log(e);
        }

        break;
      case "iCalendar":
        break;
    }
  };

  componentDidMount() {
    this.props.getProgramsName(this.props.user.authToken);
    this.fetchWorkouts();
  }

  onScheduleChange() {
    this.fetchWorkouts();
  }

  searchOnDemandVideos(dateSelected) {
    let date = moment(dateSelected).format("MM-DD-YYYY");
    if (!this.state.workouts[date]) {
      this.props.history.push('/newplatform/items/classes');
    }
    
  }

  dropdownChange = (e) => {
    this.setState({
      dropdownRef: {
        current: {
          ...this.state.dropdownRef.current,
          state: { ...this.state.dropdownRef.current.state, selected: e },
        },
      },
    });
  };

  fetchWorkouts(dateSnapshot = new Date()) {
    return fetch("/api/workout/myWorkout", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: this.props.user.authToken,
      },
      body: JSON.stringify({
        fromDate: moment("2000-01-01").utc().toISOString(),
        toDate: moment().utc().add(1, "year").toISOString(),
        when: new Date(Date.now()),
        authUserInfo: { id: this.props.user.id },
      }),
    })
      .then((res) => res.json())
      .then((res) =>
        CalendarUtil.updateWorkoutStatus(
          res.content.workouts,
          this.props.user.authToken
        )
      )
      .then((workouts) => {
        
        const result = fetch("/api/program/getProgramInfo", {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Authorization: this.props.user.authToken,
          },
          body: JSON.stringify({
            fromDate: "2000-01-01",
            toDate: moment().add(1, "year").format("YYYY-MM-DD"),
            when: new Date(Date.now()),
            authUserInfo: { id: this.props.user.id },
          }),
          })
        .then((res) => res.json())
        .then((programInformation) => {
          console.log(programInformation.content);
          programInformation = programInformation.content;
          const programId1 = [];
          const programTitle = [];
    
          for(let i = 0; i < programInformation.length; i++){
            programId1.push(programInformation[i].id);
            programTitle.push(programInformation[i].title);
          }
    
          let programIds = [];
          for(let i = 0; i < workouts.length; i++){
            programIds.push(workouts[i].programId);
          }
          console.log(programIds);
    
          let prog;
          for(let i = 0; i < workouts.length; i++){
            if((programIds[i]) == null){
            (workouts[i])["programTitle"] = "";
            }else{
            for(let j = 0; j < programId1.length; j++){
              if(programId1[j] == programIds[i]){
                prog = programTitle[j];
                break;
              }
            }
            (workouts[i])["programTitle"] = prog;
            }
          }
        });

        const dateHash = {};
        workouts.forEach((workout) => {
          const date = moment(workout.when).format("MM-DD-YYYY");
          if (!dateHash[date]) {
            dateHash[date] = [workout];
          } else {
            dateHash[date].push(workout);
          }
        });

        this.setState({ workouts: dateHash, date: dateSnapshot }, () => {
          const inRange = CalendarUtil.workoutsInRange(
            this.state.workouts,
            this.props.calendarDefaultDate
          );
          const sortedInRange = {};
          for (let date in inRange) {
            sortedInRange[date] = inRange[date].sort(
              (a, b) => moment(a.when).unix() - moment(b.when).unix()
            );
            this.buttons.push(React.createRef());
          }
          this.setState({ workoutsInCurrentMonth: sortedInRange });
        });

        return(workouts);


        //go through each workout and its information and get the program ids corresponding to each
        {/***let programIds = [];
        for (let i = 0; i < workouts.length; i++) {
          programIds.push(workouts[i].programId);
        }

        for (let i = 0; i < workouts.length; i++) {
          if ((programIds[i]) == null) {
            (workouts[i])["programTitle"] = "";
          } else {
            (workouts[i])["programTitle"] = (this.props.mapProgIdToTitle)[programIds[i]];
          }
        }
        console.log(workouts, this.state.workoutsInCurrentMonth, this.props.mapProgIdToTitle, "HIHIHI")
        return workouts;
      ***/}
      });
  }

  render() {
    const { user, calendar } = this.props;
    const { whiteLabelBackgroundStyles } = this.props;

    const syncState = calendar.sync;
    let defaultOption;
    let keys = Object.keys(syncState);
    for (let i = 0; i < keys.length; i++) {
      if (syncState[keys[i]] === "synced" || syncState[keys[i]] === "syncing") {
        defaultOption = options[i];
        break;
      }
    }

    const currDate = moment(this.state.date).format("MM-DD-YYYY");
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    let synced =
      this.state.dropdownRef.current &&
      this.state.dropdownRef.current.state.selected.value &&
      syncState[
      this.state.dropdownRef.current.state.selected.value.split(" ").join("")
      ];
    let selectedValue =
      this.state.dropdownRef.current &&
      this.state.dropdownRef.current.state.selected.value;
    let backgroundColor;

    if (synced === "unsynced") {
      backgroundColor = "rgb(152 152 152)";
      synced = "Sync Now";
    } else if (synced === "syncing") {
      backgroundColor = "rgba(30, 139, 195, 1)";
      synced = <i class="fas fa-sync fa-1x"></i>;
    } else if (synced === "synced") {
      backgroundColor =
        whiteLabelBackgroundStyles.backgroundColor || "rgb(154, 200, 185)";
      synced = "Synced ✔";
    }

    return (
      <SideBarItemContainer
        grid="True"
        defaultClassName="calendar-grid-item"
        className="calendar-grid-container"
      >
        <div className="container-content">
          <div className="calendar-container">
            <Calendar
              {...this.props}
              onChange={this.onChange}
              activeStartDate={this.props.calendarDefaultDate._d}
              value={this.props.calendarDefaultDate._d}
              showNeighboringMonth={false}
              minDetail="month"
              onActiveDateChange={this.onActiveDateChange}
              onClickDay={this.searchOnDemandVideos}
              tileContent={({ date }) => {
                date = moment(date).format("MM-DD-YYYY");
                return (
                  <CustomButton
                    {...this.props}
                    date={date}
                    workouts={
                      this.state.workouts[date]
                        ? this.state.workouts[date]
                        : [{}]
                    }
                  />
                );
              }}
              tileClassName={({ date }) => {
                date = moment(date).format("MM-DD-YYYY");
                if (this.state.workouts[date]) {
                  return this.state.workouts[date][0].status;
                }
              }}
            />

            <div className="schedContainer">
              <div className="sync">
                <Dropdown
                  className="syncRoot"
                  controlClassName="syncControl"
                  placeholderClassName="syncPlaceholder"
                  ref={this.state.dropdownRef}
                  arrowClassName="arrowClassName"
                  options={options}
                  onChange={(e) => this.dropdownChange(e)}
                  value={defaultOption || options[0]}
                  placeholder="Select Calendar..."
                />
                <button
                  onClick={() =>
                    this.sync(
                      this.state.dropdownRef.current.state.selected.value
                    )
                  }
                  style={{
                    backgroundColor,
                    color: "white",
                    border: "none",
                  }}
                  disabled={
                    selectedValue === "iCalendar" || typeof synced !== "string"
                  }
                >
                  <div style={{ width: "111px" }}>{synced}</div>
                </button>
              </div>
              {this.state.workoutsInCurrentMonth[currDate] === undefined ? (
                <div id="new-class-schedule">
                  <p>Ready for a new class?</p>
                  <Link
                    style={this.props.whiteLabelBackgroundStyles}
                    to="/newplatform/items/classes"
                    className="schedule"
                  >
                    Schedule
                  </Link>
                </div>
              ) : (
                <Slider {...settings}>
                  {this.state.workoutsInCurrentMonth[currDate].map((wkt) => (
                    <div>
                      <WorkoutSlider {...this.props} wkt={wkt} />
                    </div>
                  ))}
                </Slider>
              )}
            </div>
          </div>
          <div className="monthly-totals">
            <div>
              <div
                className="status scheduled"
                style={{
                  borderColor: this.props.whiteLabelBackgroundStyles
                    .backgroundColor,
                }}
              />
              Scheduled:{" "}
              {CalendarUtil.getTotalWorkouts(
                this.state.workoutsInCurrentMonth,
                "scheduled"
              )}{" "}
            </div>
            <div>
              <div className="status skipped" />
              Skipped:{" "}
              {CalendarUtil.getTotalWorkouts(
                this.state.workoutsInCurrentMonth,
                "skipped"
              )}
            </div>
            <div>
              <div
                className="status completed"
                style={{
                  ...this.props.whiteLabelBackgroundStyles,
                  borderColor: this.props.whiteLabelBackgroundStyles
                    .backgroundColor,
                }}
              />
              Completed:{" "}
              {CalendarUtil.getTotalWorkouts(
                this.state.workoutsInCurrentMonth,
                "completed"
              )}
            </div>
          </div>
        </div>
        <div>
          {this.state.workoutsInCurrentMonth && (
            <ScheduleContainer
              {...this.props}
              calendarDefaultDate={this.props.calendarDefaultDate}
              setCalendarDefaultDate={this.props.setCalendarDefaultDate}
              workouts={this.state.workoutsInCurrentMonth}
              onScheduleChange={this.onScheduleChange}
              mapProgIdToTitle={this.props.mapProgIdToTitle}
              user={this.props.user}
            />
          )}
        </div>
      </SideBarItemContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  calendar: state.calendarReducer,
  mapProgIdToTitle: state.video.mapProgIdToTitle
});
const mapDispatchToProps = (dispatch) => ({
  signingOut: (client) => dispatch(signingOut(client)),
  signingIn: (client, currUser) => dispatch(signingIn(client, currUser)),
  getProgramsName: (auth) => dispatch(getPrograms(auth))
});
export default connect(mapStateToProps, mapDispatchToProps)(WorkoutCalendar);
