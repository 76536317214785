import React from "react";
import "./UnknownPage.css";
import Oops from "../images/UnknownPage/oops.png";
import GoBack from "../images/UnknownPage/goback.png";
import Text from "../images/UnknownPage/text.png";
import TextMobile from "../images/UnknownPage/text-mobile.png";
import { Link } from "react-router-dom";

function UnknownPage() {
  return (
    <div className="unknown">
      <div className="container d-flex flex-column align-items-center justify-content-center mb-0">
        <img id="oops" className="center img-fluid" src={Oops} alt="" />
        <picture>
          <source media="(min-width: 600px)" srcset={Text} />
          <img id="text" src={TextMobile} alt="" />
        </picture>
        <Link to="/">
          <img
            id="goback"
            className="center img-fluid btn"
            src={GoBack}
            alt=""
          />
        </Link>
      </div>
    </div>
  );
}

export default UnknownPage;
