import React from "react";
import Calendar from "./Calendar";

//const moment = require("moment");

const CalendarContainer = () => {
  //const [month, setMonth] = useState(moment().month());
  return (
    <div>
      <Calendar />
    </div>
  );
};

export default CalendarContainer;
