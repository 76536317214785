import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Elements, ElementsConsumer } from "@stripe/react-stripe-js";
import { sendLiveClassBookingConfirmation } from "redux/actions/liveclass.actions";
import JetsweatModal from "../JetsweatModal";
import CheckoutForm from "./LiveClassCheckout";
import { months, days } from "../VideoFilters/constants";
import authFetch from "../../authFetch/authFetch";

class PaymentModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stripeApiKey: "",
      subscription: "",
      firstName: "",
      lastName: "",
    };
  }

  componentDidMount() {
    try {
      authFetch("api/user/subscription", {
        headers: { Authorization: this.props.currUser.authToken },
      }).then((body) => {
        if (!body.status) {
          throw new Error("API Error");
        }
        this.props.currUser.subscription = body.content;
      });
    } catch (err) {
      console.log(err);
    }

    fetch("/api/basic/stripe", {
      method: "GET",
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((res) => {
        if (res.status >= 400) {
          console.log("error!");
        } else {
          return res.json();
        }
      })
      .then((rslt) => {
        this.setState({
          stripeApiKey: (rslt.content || {}).publishable || "",
        });

        return rslt;
      });
  }

  render() {
    const { selectedClass, sendLiveClassBookingConfirmation } = this.props;

    // const date = this.props.selectedClass.scheduledDatetime ? new Date(this.props.selectedClass.scheduledDatetime) : new Date()
    const date = new Date(this.props.selectedClass.scheduledDatetime);
    const militaryHour = date.getHours();
    let hours = militaryHour > 12 ? militaryHour % 12 : militaryHour;
    const mins =
      date.getMinutes() < 10 ? `${date.getMinutes()}0` : date.getMinutes();
    const period = militaryHour < 12 ? "AM" : "PM";
    if (hours === 0) {
      hours = 12;
    }
    return (
      <JetsweatModal
        showModal={this.props.showModal}
        closeModal={this.props.closeModal}
        whiteLabelBackgroundStyles={this.props.whiteLabelBackgroundStyles}
        title={this.props.title}
        customStyles={this.props.customStyles}
      >
        <div className="payment-columns-container row">
          <div className="payment-columns-1 col-5">
            <h3>Date</h3>
          </div>
          <div className="payment-columns-2 col-7">
            <h3>
              {days[date.getDay()]}, {months[date.getMonth()]} {date.getDate()},{" "}
              {date.getFullYear()}
            </h3>
          </div>
          <div className="payment-columns-1 col-5">
            <h3>Time</h3>
          </div>
          <div className="payment-columns-2 col-7">
            <h3>{`${hours}:${mins}${period}`}</h3>
          </div>
          <div className="payment-columns-1 col-5">
            <h3>Class</h3>
          </div>
          <div className="payment-columns-2 col-7">
            <h3>{selectedClass.title}</h3>
          </div>
          <div className="payment-columns-1 col-5">
            <h3>Amount Due</h3>
          </div>
          <div className="payment-columns-2 col-7">
            <h3>
              {this.props.successPromo === true ? (
                <div>
                  {this.props.coupon.content.newAmount === 0 ? (
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          textDecoration: "line-through",
                          paddingRight: "5px",
                        }}
                      >
                        ${selectedClass.price}
                      </div>
                      <div style={{ color: "#89CFBA" }}>FREE</div>
                    </div>
                  ) : (
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          textDecoration: "line-through",
                          paddingRight: "5px",
                        }}
                      >
                        ${selectedClass.price}
                      </div>
                      <div style={{ color: "#89CFBA" }}>
                        ${this.props.coupon.content.newAmount}
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  {selectedClass.price || selectedClass.price === 0
                    ? `$${selectedClass.price}`
                    : `$${10}`}
                </div>
              )}
            </h3>
          </div>
        </div>
        <hr />

        <Elements stripe={this.props.stripePromise}>
          <ElementsConsumer>
            {({ stripe, elements }) => (
              <CheckoutForm
                {...this.props}
                sendLiveClassBookingConfirmation={sendLiveClassBookingConfirmation.bind(
                  this
                )}
                selectedClass={selectedClass}
                authToken={this.props.currUser.authToken}
                user={this.props.currUser}
                stripe={stripe}
                elements={elements}
                closeModal={this.props.closeModal}
                orderSummaryModal={this.props.orderSummaryModal}
                coupon={this.props.coupon}
                couponSuccess={this.props.successPromo}
              />
            )}
          </ElementsConsumer>
        </Elements>
      </JetsweatModal>
    );
  }
}

const mSTP = (state) => ({
  success: state.liveclass.success,
  coupon: state.basic.coupon,
  successPromo: state.basic.success,
});

const mDTP = (dispatcher) =>
  bindActionCreators(
    {
      sendLiveClassBookingConfirmation,
    },
    dispatcher
  );

export default connect(mSTP, mDTP)(PaymentModal);
