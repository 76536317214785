import React from "react";
import star_light from "../assets/icons/star_light.svg";
import star_solid from "../assets/icons/star_solid.svg";
import '../styles/Comment.css' 

const Comment = ({ userName, userImage, rating, comment }) => {
  return (
    <div className='comment-container'>
      <div className='comment-stars'>
        {[...Array(5)].map((star, index) => {
          const ratingValue = index + 1;
          return (
            <img
              key={index}
              src={ratingValue <= rating ? star_solid : star_light}
              alt="star"
            />
          );
        })}
      </div>
      <div className='comment-section'>
        <p>
          {comment}
        </p>
      </div>
      <div className='comment-user-section'>
        <img src={userImage} alt="user" />
        <h2>
          {userName}
        </h2>
      </div>
    </div>
  );
};

export default Comment;
