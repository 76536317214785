import React from "react";
import Header from "./components/Header";
import RituWell from "./components/RituWell";
import Footer from '../../components/footer.js';

const HomePage = () => {
  return (
    <div style={{overflow:'hidden'}}>
      <h1>HEADER</h1>
      <Header />
      <RituWell />
      <Footer />
    </div>
  );
};

export default HomePage;
