export const ActionTypes = {
  SEND_LIVE_CLASS_BOOKING_CONFIRMATION: 'SEND_LIVE_CLASS_BOOKING_CONFIRMATION',
  SEND_LIVE_CLASS_BOOKING_CONFIRMATION_SUCCESS: 'SEND_LIVE_CLASS_BOOKING_CONFIRMATION_SUCCESS',
  SEND_LIVE_CLASS_BOOKING_CONFIRMATION_FAILURE: 'SEND_LIVE_CLASS_BOOKING_CONFIRMATION_FAILURE',
  GET_PURCHASED_LIVE_CLASSES: 'GET_PURCHASED_LIVE_CLASSES',
  GET_PURCHASED_LIVE_CLASSES_SUCCESS: 'GET_PURCHASED_LIVE_CLASSES_SUCCESS',
  GET_PURCHASED_LIVE_CLASSES_FAILURE: 'GET_PURCHASED_LIVE_CLASSES_FAILURE',
  FETCH_LIVE_VIDEOS_JETSWEAT_PUBLIC: 'FETCH_LIVE_VIDEOS_JETSWEAT_PUBLIC',
  FETCH_LIVE_VIDEOS_JETSWEAT_PUBLIC_SUCCESS: 'FETCH_LIVE_VIDEOS_JETSWEAT_PUBLIC_SUCCESS',
  FETCH_LIVE_VIDEOS_JETSWEAT_PUBLIC_FAILURE: 'FETCH_LIVE_VIDEOS_JETSWEAT_PUBLIC_FAILURE',
  ENABLE_FULL_SCREEN: 'ENABLE_FULL_SCREEN',
  DISABLE_FULL_SCREEN: 'DISABLE_FULL_SCREEN'
}

export const sendLiveClassBookingConfirmation = (amount, studioId, paymentIntentId, liveClassId, promo, headers) => {
  return {
    type: ActionTypes.SEND_LIVE_CLASS_BOOKING_CONFIRMATION,
    payload: {
      amount,
      studioId,
      paymentIntentId,
      liveClassId,
      promo,
      headers
    },
  }
}

export const getPurchasedLiveClasses = (headers) => {
  return {
    type: ActionTypes.GET_PURCHASED_LIVE_CLASSES,
    payload: {
      headers
    },
  }
}

export const fetchLiveVideosJetsweatPublic = (id, headers) => {
  return {
    type: ActionTypes.FETCH_LIVE_VIDEOS_JETSWEAT_PUBLIC,
    payload: {
      id,
      headers
    },
  }
}

export const enableFullScreen = () => {
  return {
      type: ActionTypes.ENABLE_FULL_SCREEN, 
  }
}

export const disableFullScreen = () => {
  return {
      type: ActionTypes.DISABLE_FULL_SCREEN, 
  }
}