import { ActionTypes } from 'redux/actions/liveclass.actions'

const {
  SEND_LIVE_CLASS_BOOKING_CONFIRMATION,
  SEND_LIVE_CLASS_BOOKING_CONFIRMATION_SUCCESS,
  SEND_LIVE_CLASS_BOOKING_CONFIRMATION_FAILURE,
  GET_PURCHASED_LIVE_CLASSES,
  GET_PURCHASED_LIVE_CLASSES_SUCCESS,
  GET_PURCHASED_LIVE_CLASSES_FAILURE,
  FETCH_LIVE_VIDEOS_JETSWEAT_PUBLIC_SUCCESS,
  ENABLE_FULL_SCREEN,
  DISABLE_FULL_SCREEN
} = ActionTypes;

const initialState = {
  success: null,
  purchased: [],
  liveVideos: [],
  fullScreen: null
}

export default function liveclassReducer(state = initialState, action) {
  switch (action.type) {
    case SEND_LIVE_CLASS_BOOKING_CONFIRMATION:
      return {
        ...state,
        success: null
      }
    case SEND_LIVE_CLASS_BOOKING_CONFIRMATION_SUCCESS:
      return {
        ...state,
        success: true
      }
    case SEND_LIVE_CLASS_BOOKING_CONFIRMATION_FAILURE:
      return {
        ...state,
        success: false
      }
    case GET_PURCHASED_LIVE_CLASSES_SUCCESS:
      return {
        ...state,
        purchased: action.purchased,
        success: true
      }
    case FETCH_LIVE_VIDEOS_JETSWEAT_PUBLIC_SUCCESS:
      return {
        ...state,
        liveVideos: action.liveVideos,
        success: true
      }
    case ENABLE_FULL_SCREEN:
      return {
        ...state, 
        fullScreen: true
      }  
    case DISABLE_FULL_SCREEN: 
      return {
        ...state, 
        fullScreen: false
      }  
    default:
      return state
  }
}
