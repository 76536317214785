import React, { Component } from 'react';

class FAQ extends Component {
  constructor(props) {
    super(props);

    this.state = {
      question: `${this.props.faqs.question}`,
      answer: `${this.props.faqs.answer}`
    };
  }

  render() {
    return (
      <div>
        <p>{this.state.question}</p>
        <p>{this.state.answer}</p>
      </div>
    );
  }
}

export default FAQ;
