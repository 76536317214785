import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

export const WhiteLabelCheckoutConfirmationPdf = ({product, whiteLabelBackgroundStyles, currentUser, studio}) => {
    return (
        <div className="account">
            <div className="account__box">
                <section className="account__box--3" style={whiteLabelBackgroundStyles}>
                    <div className="check__circle--box-whitelabel">
                        <FontAwesomeIcon
                            className="check__circle--whiteLabel"
                            icon={faCheckCircle}
                        />
                    </div>

                    <div className="whiteLabel margin__top--2">
                        <h1 className="font--weight font--size-big">
                            {currentUser.firstName}, Your Purchase is Confirmed!
                        </h1>
                        <div className="info-box">
                            <h3 className="font--weight font--size-2 mobile-version">
                                Check your inbox for a confirmation email.
                            </h3>
                        </div>
                    </div>
                </section>
                <section className="account__box--2 font--weight">
                    <div className="get-started__box margin__top--big">
                        <h2 className="margin__top--4 font--weight font--size-1">
                            Thank you for purchasing {product?.title}!
                        </h2>
                        <h4 className="margin__top--4 font--weight font--size-1">
                            Here’s how you can get started:
                        </h4>
                        <h4 className="margin__top--3 font--weight font--size-2">
                            1) Check your inbox for the confirmation email containing your purchase.
                        </h4>
                    </div>
                </section>
            </div>
        </div>
    )
}