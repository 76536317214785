import { all } from 'redux-saga/effects'
import livestream from './sagas/livestream.sagas'
import userfriends from './sagas/userfriends.sagas'
import live from './sagas/live.sagas'
import liveclass from './sagas/liveclass.sagas'
import events from "./sagas/events.sagas"
import basic from "./sagas/basic.sagas";
import stripe from "./sagas/stripe.sagas"
import favorites from "./sagas/favorites.sagas"
import profile from "./sagas/profile.sagas"
import video from "./sagas/video.sagas"
import favoriteclasstypes from "./sagas/favoriteclasstypes.sagas"
import favoritestudios from "./sagas/favoritestudios.sagas"
import antmedia from './sagas/antmedia.sagas'


export default function* rootSaga() {
  yield all([
    livestream(),
    userfriends(),
    live(),
    liveclass(),
    basic(),
    stripe(),
    events(),
    favorites(),
    profile(),
    video(),
    favoriteclasstypes(),
    favoritestudios(),
    antmedia(),

  ])
}
