import React from 'react';

export default function RenderClassTypes({ classTypes, icons }) {
  const mapTypes = classTypes.map(type => (
    <p key={`type-${type}`}>
      <img src={icons.classTypes} alt={type} className="classbullet" />
      {type}
    </p>
  ));

  return classTypes.length === 0 ? <>no types</> : (
    <>
      <p className="replace-h2"> Class types </p>
      {mapTypes}
    </>
  );
}
