import React from 'react';
import packageJson from '../package.json';
import toBoolean from "validator/es/lib/toBoolean";

class CacheBuster extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            isLatestVersion: false,
            refreshCacheAndReload: () => {
                console.log('Clearing cache and hard reloading...')
                if (caches) {
                    // Service worker cache should be cleared with caches.delete()
                    caches.keys().then(function(names) {
                        for (let name of names) caches.delete(name);
                    });
                }
                // delete browser cache and hard reload
                window.location.reload(true);
            }
        };
    }

    componentDidMount() {
        fetch('/meta.json')
            .then((response) => response.json())
            .then((meta) => {
                const latestVersion = meta.version;
                const currentVersion = packageJson.version;
                const disableCacheBuster = toBoolean(process.env.REACT_APP_DISABLE_CACHE_BUSTER);
                const shouldForceRefresh = latestVersion !== currentVersion;
                
                console.log(
                  'CacheBuster',
                  `latestVersion: ${latestVersion}`, 
                  `currentVersion: ${currentVersion}`, 
                  `shouldForceRefresh=${shouldForceRefresh}`, 
                  `disableCacheBuster=${disableCacheBuster}`
                );
                
                if (disableCacheBuster) {
                  console.log(`Cache busting is disabled.`);
                  this.setState({ loading: false, isLatestVersion: true });
                } else if (shouldForceRefresh) {
                  console.log(`We have a new version - ${latestVersion}. Should force refresh`);
                  this.setState({ loading: false, isLatestVersion: false });
                } else {
                  console.log(`You already have the latest version - ${latestVersion}. No cache refresh needed.`);
                  this.setState({ loading: false, isLatestVersion: true });
                }
            });
    }
    render() {
        const { loading, isLatestVersion, refreshCacheAndReload } = this.state;
        return this.props.children({ loading, isLatestVersion, refreshCacheAndReload });
    }
}

export default CacheBuster;
