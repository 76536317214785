import React , {useState, useEffect} from "react";
import Laptop from "../assets/images/Laptop.svg";
import Logos from "../assets/images/Logos.svg";
import Meeting from "../assets/images/Meeting.png";
import closeButton from "../../../assets/Vector.png";
import Modal from 'react-modal';
import "../styles/Header.css";

const Header = () => {
  const [openModal, setOpenModal] = useState(false);
  // Hubspot react forms
  useEffect(()=>{
    console.log('react hubspot')
    const script = document.createElement('script');
    script.src = '//js.hsforms.net/forms/embed/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "23498037",
          formId: "9649ccc1-a6e6-4a67-80bd-4cce292e497c",
          target: '#hubspotFormModal' 
        });
      }
    });

    return () => {
      // Clean up: Remove the script from the document on component unmount
      document.body.removeChild(script);
    };
  }, [openModal])

  const handleOpenModal = (e) => {
    e.preventDefault();
    setOpenModal(true);
    document.body.classList.add('workplace-modal-open');
  }
  const handleCloseModal = (e) => {
    e.preventDefault();
    setOpenModal(false);
    document.body.classList.remove('workplace-modal-open');
  }
  return (
    <>
      <div className="header-container">
        <div className="text-container" data-aos="fade-right">
          <div>
            <h1>Tailored Wellness Solutions for Your Team</h1>
            <h2>Anytime. Anywhere.</h2>
            <p>
              Experience the power of personalized fitness, mindfulness and
              holistic health with RituWell by JETSWEAT
            </p>
          </div>
          <button onClick={handleOpenModal}>Give it a try</button>
        </div>
        <div className="image-container" data-aos="fade-left">
          <img src={Laptop} alt="Laptop" />
        </div>
      </div>
      <div className='header-mid-section'>
        <div className="feature-container">
          <p>Featured On</p>
          <div className="scroll-container">
            <img src={Logos} alt="Logos" />
          </div>
        </div>
        <div className="transition-container">
          <div data-aos='fade-up' data-aos-delay='300' className="transition-text-container-header">
            <h3>Ready to create a Thriving Workplace?</h3>
            <h1>Jetsweat makes it possible with Happy, Healthy Employees</h1>
          </div>
          <img src={Meeting} alt="Meeting" className="meeting-img" />
        </div>
      </div>
      

      <Modal style={{overlay: {backgroundColor: 'rgba(0,0,0,0.75'}}} 
        isOpen={openModal} 
        className={`workplace-modal ${openModal ? 'open' : ''}`}
        shouldCloseOnOverlayClick={true}
        >
        
        <div className='modal-main'>
          <button 
            className="close-modal-button" 
            onClick={handleCloseModal}
          ><img src={closeButton}/> </button>
          <div className='modal-header'>
            <h3>Get In Contact</h3>
            <p>Ask about our flexible pricing options!</p>
          </div>
          <div id="hubspotFormModal"  className='hubspot-form'></div>
        </div>
        
      </Modal> 
    </>
  );
};

export default Header;
