import React, { Component } from "react";
import { Link } from "react-router-dom";
import Media from "react-media";
import NavBarOptions from "../hamburgerNavBarOptions";
import "../navbar.css";

function LogButton(props) {
  if (props.isLoggedIn) {
    return (
      <Link to="/logout" className="Tabs-button">
        Log Out
      </Link>
    );
  }
  return (
    <Link to="/login" className="Tabs-button">
      Log In
    </Link>
  );
}

class StudiosHeading extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      isToggled: false,
      width: window.outerWidth,
      hamburgerOptionsHidden: true,
    };

    this.showHamburgerNavBarOptions = this.showHamburgerNavBarOptions.bind(
      this
    );
  }

  showHamburgerNavBarOptions() {
    if (this.state.hamburgerOptionsHidden) {
      this.setState({ hamburgerOptionsHidden: false });
    } else {
      this.setState({ hamburgerOptionsHidden: true });
    }
  }

  render() {
    return (
      <div>
        <header className="StudiosHeading">
          <Link className="Button-hover" to="/">
            {this.props.studios()}
          </Link>

          <ul className="Tabs-container">
            {this.props.isLoggedIn && (
              <li className="Tabs">
                <Link
                  to="/newplatform/dashboard"
                  className="Tabs-button Tabs-button--outline"
                >
                  Dashboard
                </Link>
              </li>
            )}

            <li className="tabs-button">
              <Link to="/how-it-works">How it Works</Link>
            </li>
            <li className="tabs-button">
              <a href="https://www.blog.jetsweatfitness.com"> Blog </a>
            </li>
            <li className="tabs-button">
              <a href="https://www.blog.jetsweatfitness.com/new-products/">
                {" "}
                Gear{" "}
              </a>
            </li>
            <li className="tabs-button">
              <LogButton isLoggedIn={this.props.isLoggedIn} />
            </li>

            <li>
              <Link
                to="/signup"
                className="try-for-free-button"
                style={{ color: "white" }}
              >
                Start Your Free Trial
              </Link>
            </li>

            <Media query="(max-width: 800px)">
              {(matches) =>
                matches ? (
                  <span className="open-slide">
                    <a href="#!">
                      <svg
                        width="30"
                        height="30"
                        onClick={this.showHamburgerNavBarOptions}
                      >
                        <path d="M0,5 30,5" stroke="#2C2C2C" strokeWidth="5" />
                        <path
                          d="M0,14 30,14"
                          stroke="#2C2C2C"
                          strokeWidth="5"
                        />
                        <path
                          d="M0,23 30,23"
                          stroke="#2C2C2C"
                          strokeWidth="5"
                        />
                      </svg>
                    </a>
                  </span>
                ) : (
                  <p />
                )
              }
            </Media>
          </ul>
        </header>

        <NavBarOptions
          studioInfoList={this.props.studioInfo}
          hidden={this.state.hamburgerOptionsHidden}
          showHamburgerNavBarOptions={this.showHamburgerNavBarOptions}
        />
      </div>
    );
  }
}
export default StudiosHeading;
