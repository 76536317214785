/* eslint-disable */
import React, { useState } from "react";

export default function RenderInstructors({ instructors }) {
  // From DB: inst.imageUrl
  // From local: inst.photoUrl
  const mapInstructors = instructors.map((inst, i) => {
    const divStyle = {
      backgroundImage: `url("${
        inst.imageUrl === ""
          ? "https://jetsweat-website-frontend.s3.us-east-2.amazonaws.com/dev/src/images/allStudios/00-defaults/Slice+1.png"
          : inst.imageUrl
      }")`,
      content: "",
      position: "absolute",
      height: "100%",
      width: "100%",
      top: "0",
      left: "0",
      backgroundSize: "cover",
      backgroundPosition: "center"
    };

    const [showBio, toggleShowBio] = useState(false);

    return (
      <div
        className="singlestudio-instructor-name"
        key={`inst-${i}`}
        onMouseEnter={() => toggleShowBio(!showBio)}
        onMouseLeave={() => toggleShowBio(!showBio)}
      >
        <div style={divStyle} className={showBio ? "image-hover" : ""} />
        <div className="singlestudio-instructor-bg">
          <div className="instructor-text">
            <p className="text-element">{inst.name}</p>
            <p className="text-element bio">{inst.bio}</p>
          </div>
        </div>
      </div>
    );
  });

  return instructors.length === 0 ? (
    <></>
  ) : (
    <div className="meettheinstructors">
      <p className="section-title">Meet The Instructors</p>
      {mapInstructors}
    </div>
  );
}
