
import React, { Component } from 'react'
import {
  Player,
  ControlBar,
  BigPlayButton
} from 'video-react'
import HLSSource from 'views/New_Platform/Live/HLSSource'
import './homepagevideoplayer.css'


export default class HomePageVideoPlayer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isPlaying: false,
    }
  }

  componentDidMount() {

  }

  onPlaying() {
    this.setState({
      isPlaying: true,
    })
  }

  getOverlay() {
    if (this.state.isPlaying) return <div className="overlay" />
    return null
  }

  async onLoadedData(event) {
    try {
      await event.target.play();
    } catch(e) {
      //Do nothing for now
      //TODO: Consider retry or providing error message to user
    }
  }


  render() {
    let { videoUrl } = this.props 

    return (
      <div className="homepage-video-container">
        {this.getOverlay()}

        <div className="js-video-background">
          <Player
            onLoadedData={this.onLoadedData.bind(this)}
            className="home-video"
            onPlaying={this.onPlaying.bind(this)}
            autoPlay={true}
            playsInline={true}
            loop={true}
            muted={true}
          >
            <HLSSource
              isVideoChild
              src={videoUrl}
            />
            <BigPlayButton disabled />
            <ControlBar disabled />
          </Player>
        </div>
      </div>
    )
  }
}
