
import { put, call, all, takeLatest, takeEvery } from 'redux-saga/effects'
import { ActionTypes } from 'redux/actions/livestream.actions'
import { get } from 'redux/api';
/*import sailsIOClient from 'sails.io.js';
import socketIOClient from 'socket.io-client';
const io = sailsIOClient(socketIOClient);
io.sails.url = 'http://localhost:1337';
const socket = io.sails.connect('http://localhost:1337');*/

const basePath = '/livestream'

const OFFER_OPTIONS = {
  offerToReceiveAudio: 1,
  offerToReceiveVideo: 1
};

export const joinLiveClass = function* (action) {
  try {
    yield put({ type: ActionTypes.JOIN_LIVE_CLASS_SUCCESS })
  } catch (error) {
    yield put({ type: ActionTypes.JOIN_LIVE_CLASS_FAILURE })
  }
}

export const sendVideoOffer = function* (action) {
  try {
    yield put({ type: ActionTypes.SEND_VIDEO_OFFER_SUCCESS, errorMessage: '' })
  } catch (error) {
    yield put({ type: ActionTypes.SEND_VIDEO_OFFER_FAILURE, errorMessage: '' })
  }
}

export const sendVideoAnswer = function* (action) {
  try {
    yield put({ type: ActionTypes.SEND_VIDEO_ANSWER_SUCCESS, errorMessage: '' })
  } catch (error) {
    yield put({ type: ActionTypes.SEND_VIDEO_ANSWER_FAILURE, errorMessage: '' })
  }
}

export const sendLiveClassMessage = function* (action) {
  try {
    let { message } = action.payload;
    yield put({ type: ActionTypes.SEND_LIVE_CLASS_MESSAGE_SUCCESS, errorMessage: '' })
  } catch (error) {
    yield put({ type: ActionTypes.SEND_LIVE_CLASS_MESSAGE_FAILURE, errorMessage: '' })
  }
}

export const createOffer = function* (action) {
  try {
    let { pc, peerUuid, dest, peerUserType, callback } = action.payload;
    const response = yield pc.createOffer(OFFER_OPTIONS)
    yield pc.setLocalDescription(response)
    yield put({ type: ActionTypes.CREATE_OFFER_SUCCESS, errorMessage: '', offer: response, offer_peeruuid: peerUuid, offer_dest: dest, offer_peerusertype: peerUserType, callback })
  } catch (error) {
    yield put({ type: ActionTypes.CREATE_OFFER_FAILURE, errorMessage: '' })
  }
}

export const createAnswer = function* (action) {
  try {
    let { offer, peerConnection, peerUuid, dest, callback  } = action.payload;
    console.log(peerConnection.pc, "hereee")
    yield peerConnection.pc.setRemoteDescription(offer)
    const answer = yield peerConnection.pc.createAnswer()

    console.log(peerConnection.pc, "hereeeee")
    yield peerConnection.pc.setLocalDescription(answer)
    yield put({ type: ActionTypes.CREATE_ANSWER_SUCCESS, errorMessage: '', answer, answer_peeruuid: peerUuid, answer_dest: dest, callback })
  } catch (error) {
    console.log(error, "error")
    yield put({ type: ActionTypes.CREATE_ANSWER_FAILURE, errorMessage: '' })
  }
}

export const setAnswer = function* (action) {
  try {
    let { answer, peerConnection  } = action.payload;
    yield peerConnection.pc.setRemoteDescription(answer)
    yield put({ type: ActionTypes.SET_ANSWER_SUCCESS })
  } catch (error) {
    console.log(error, "error SET_ANSWER_FAILURE")
    yield put({ type: ActionTypes.SET_ANSWER_FAILURE })
  }
}

export const addIceCandidate = function* (action) {
  try {
    let { icecandidate, peerConnection  } = action.payload;
    yield peerConnection.pc.addIceCandidate(icecandidate);
    yield put({ type: ActionTypes.ADD_ICE_CANDIDATE_SUCCESS })
  } catch (error) {
    console.log("ADD ICE FAILUREEE", error)
    yield put({ type: ActionTypes.ADD_ICE_CANDIDATE_FAILURE })
  }
}

function* livestream() {
  yield all([
    takeLatest(ActionTypes.SEND_VIDEO_OFFER, sendVideoOffer),
    takeLatest(ActionTypes.SEND_VIDEO_ANSWER, sendVideoAnswer),
    takeLatest(ActionTypes.JOIN_LIVE_CLASS, joinLiveClass),
    takeLatest(ActionTypes.SEND_LIVE_CLASS_MESSAGE, sendLiveClassMessage),
    takeEvery(ActionTypes.CREATE_OFFER, createOffer),
    takeEvery(ActionTypes.CREATE_ANSWER, createAnswer),
    takeEvery(ActionTypes.SET_ANSWER, setAnswer),
    takeEvery(ActionTypes.ADD_ICE_CANDIDATE, addIceCandidate),
  ])
}

export default livestream
