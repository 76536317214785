import React from 'react'
import moment from 'moment'

// import { Button } from 'semantic-ui-react';

import './WorkoutSlider.css'

function WorkoutSlider(props) {
  const { wkt, idx } = props
  const date = moment(wkt.when)
  const minutes = Math.floor(
    wkt.video ? wkt.video.duration / 60 : wkt.liveClass.minutesDuration
  )
  const seconds = wkt.video ? wkt.video.duration - minutes * 60 : 0

  const status = wkt.status[0].toUpperCase() + wkt.status.slice(1)
  const { whiteLabelBackgroundStyles, whiteLabelFontStyles } = props
  let inlineStyles
  if (whiteLabelBackgroundStyles.backgroundColor) {
    inlineStyles = {
      color: whiteLabelFontStyles.color,
      border: `2px solid ${whiteLabelFontStyles.color}`,
    }
  }
  return (
    <div className="slider-instance">
      <span className="upcoming" style={inlineStyles}>
        {status || 'Upcoming'}
      </span>
      <br /> <br />
      <a
        href={
          wkt.video
            ? `/newplatform/items/classes/${wkt.video.id}`
            : `/newplatform/live/upcoming/${wkt.liveClass.studioId}`
        }
        key={idx}
        className="workout"
      >
        <span className="title">
          {wkt.video ? wkt.video.title : wkt.liveClass.title}
        </span>
      </a>
      <br />
      <p className="time" style={inlineStyles}>
        <span>{date.format('MM/DD/YYYY')}</span>
        <span>{date.format('hh:mm A')}</span>
      </p>
      <hr />
      <span>{wkt.video ? wkt.video.instructor : wkt.liveClass.instructor}</span>
      <br />
      <span>{`${minutes} mins ${seconds} secs`}</span>
    </div>
  )
}
export default WorkoutSlider
