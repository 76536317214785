export const ActionTypes = {
    CREATE_BROADCAST: "CREATE_BROADCAST",
    CREATE_BROADCAST_SUCCESS: "CREATE_BROADCAST_SUCCESS",
    CREATE_BROADCAST_FAILURE: "CREATE_BROADCAST_FAILURE",
    DELETE_BROADCAST: "DELETE_BROADCAST",
    DELETE_BROADCAST_SUCCESS: "DELETE_BROADCAST_SUCCESS",
    DELETE_BROADCAST_FAILURE: "DELETE_BROADCAST_FAILURE",
    DELETE_THEN_CREATE_BROADCAST: "DELETE_THEN_CREATE_BROADCAST",
    DELETE_THEN_CREATE_BROADCAST_FAILURE: "DELETE_THEN_CREATE_BROADCAST_FAILURE",
    DELETE_THEN_CREATE_BROADCAST_SUCCESS: "DELETE_THEN_CREATE_BROADCAST_SUCCESS"

}


export const createBroadcast = function (streamId, liveClassId) {
    return {
        type: ActionTypes.CREATE_BROADCAST,
        payload: {
            streamId,
            liveClassId
        }
    }
}

export const deleteThenCreateBroadcast = function (streamId, liveClassId) {
    return {
        type: ActionTypes.DELETE_THEN_CREATE_BROADCAST,
        payload: {
            streamId,
            liveClassId
        }
    }
}


export const deleteBroadcast = function (streamId) {
    return {
        type: ActionTypes.DELETE_BROADCAST,
        payload: {
            streamId
        }
    }
}