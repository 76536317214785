import React, { Component } from 'react';

class Sandbox extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <iframe width="100%" height="600px" src="https://streaming.jetsweatlive.com/live" />
      </div>
    );
  }
}

export default Sandbox;
