
import { put, call, all, takeLatest, takeEvery } from 'redux-saga/effects'
import { ActionTypes } from 'redux/actions/liveclass.actions'
import { get, post, patch, deleteRequest } from 'redux/api';

const basePath = '/api/liveclass'

export const sendLiveClassBookingConfirmation = function* (action) {
  try {
    const { amount, studioId, paymentIntentId, liveClassId, promo, headers } = action.payload

    const response = yield call(post, `${basePath}/upcoming/${liveClassId}/confirmation`, {
      amount,
      studioId,
      paymentIntentId,
      promo
    }, headers)
    const data = yield response.json()
    if (response.status >= 200 && response.status < 300) {
      yield put({ type: ActionTypes.SEND_LIVE_CLASS_BOOKING_CONFIRMATION_SUCCESS, success: true })
    } else {
      throw data
    }
  } catch (error) {
    yield put({ type: ActionTypes.SEND_LIVE_CLASS_BOOKING_CONFIRMATION_FAILURE, success: false })
  }
}

export const getPurchasedLiveClasses = function* (action) {
  try {
    const { headers } = action.payload
    const response = yield call(get, `${basePath}/purchased`, "", headers)
    const data = yield response.json()
    if (response.status >= 200 && response.status < 300) {
      yield put({ type: ActionTypes.GET_PURCHASED_LIVE_CLASSES_SUCCESS, purchased: data.content.purchased, success: true })
    } else {
      throw data
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_PURCHASED_LIVE_CLASSES_FAILURE, success: false })
  }
}

export const fetchLiveVideosJetsweatPublic = function* (action) {
  try {
    const { headers, id } = action.payload
    let query = []

    if (id) {
      query.push(`studioId=${id}`)
    }

    let response = yield fetch(`/api/liveclass/upcoming/public/all${query.length > 0 ? `?${query.join("&")}` : ''}`, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      },
    })
    let data = yield response.json()
    if (response.status >= 200 && response.status < 300) {
      yield put({ type: ActionTypes.FETCH_LIVE_VIDEOS_JETSWEAT_PUBLIC_SUCCESS, liveVideos: data.content })
    } else {
      throw data
    }
  } catch (error) {
    yield put({ type: ActionTypes.FETCH_LIVE_VIDEOS_JETSWEAT_PUBLIC_FAILURE, success: false })
  }
}

function* liveclass() {
  yield all([
    takeEvery(ActionTypes.SEND_LIVE_CLASS_BOOKING_CONFIRMATION, sendLiveClassBookingConfirmation),
    takeEvery(ActionTypes.GET_PURCHASED_LIVE_CLASSES, getPurchasedLiveClasses),
    takeEvery(ActionTypes.FETCH_LIVE_VIDEOS_JETSWEAT_PUBLIC, fetchLiveVideosJetsweatPublic)

  ])
}

export default liveclass
