const moment = require('moment');

export const formatDate = (date = Date.now(), exact = false) => {
  let d;
  exact ? (d = new Date()) : (d = new Date(date + 31556952000));
  let month = `${d.getMonth() + 1}`;

  let day = `${d.getDate()}`;

  const year = d.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return [year, month, day].join('-');
};

export const formatWorkoutDate = (date, format = 'mm-dd-yyyy') => {
  const d = new Date(date);

  let month = `${d.getMonth() + 1}`;

  let day = `${d.getDate()}`;

  const year = d.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;
  switch (format) {
    case 'mm-dd-yyyy':
      return [month, day, year].join('-');
    case 'yyyy-mm-dd':
      return [year, month, day].join('-');
    default:
      break;
  }
};

export const formatWorkoutWhen = (date = new Date()) => {
  const month = date.getMonth() + 1;
  date = date.toString().split(/[" ":]/);
  return `${date[3]}-${month}-${date[2]} ${date[4]}:${date[5]}`;
};

export const workoutsInRange = (workouts, startInput) => {
  const inRange = {};
  const start = moment(startInput).startOf('month');
  const end = moment(startInput).endOf('month');
  Object.keys(workouts).forEach((wkt) => {
    const wktTime = moment(wkt, 'MM-DD-YYYY');
    if (wktTime >= start && wktTime <= end) {
      inRange[wkt] = workouts[wkt];
    }
  });
  return inRange;
};

export const updateWorkoutStatus = async (workouts, authToken) => {
  for (let i = 0; i < workouts.length; i++) {
    if (
      new Date(workouts[i].when) < new Date()
      && workouts[i].status !== 'skipped'
      && workouts[i].status !== 'completed'
    ) {
      await fetch('/api/workout/updateStatus', {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
          Authorization: authToken,
        },
        body: JSON.stringify({
          workoutId: workouts[i].id,
          status: 'skipped',
        }),
      });
      workouts[i].status = 'skipped';
    } else if (workouts[i].status === null) {
      workouts[i].status = 'scheduled';
    }
  }
  return workouts;
};

export const getTotalWorkouts = (workouts, status) => {
  if (Object.keys(workouts).length < 1) return 0;
  return Object.keys(workouts).reduce((acc, key) => {
    let currentSum = 0;
    workouts[key].forEach((wkt) => {
      wkt.status === status ? (currentSum += 1) : (currentSum += 0);
    });
    return acc + currentSum;
  }, 0);
};
