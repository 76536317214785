export const ActionTypes = {

    GET_PROGRAMS: "GET_DETAIL_PROGRAMS",
    GET_PROGRAMS_SUCCESS: "GET_PROGRAMS_SUCCESS",
    GET_PRORAMS_FAILURE: "GET_PROGRAMS_FAILURE",
    GET_ALL_CLASSES: "GET_ALL_CLASSES_INFO",
    GET_ALL_CLASSES_SUCCESS: "GET_ALL_CLASSES_INFO_SUCCESS",
    GET_ALL_CLASSES_FAILURE: "GET_ALL_CLASSES_INFO_FAILURE"
}

export const getPrograms = function (headers) {
    return {
        type: ActionTypes.GET_PROGRAMS,
        payload: {
            headers
        }
    }
}

export const getAllClasses = function (headers) {
    return {
        type: ActionTypes.GET_ALL_CLASSES,
        payload: {
            headers
        }
    }
}