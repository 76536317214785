import { put, call, all, takeLatest } from 'redux-saga/effects'
import { ActionTypes } from '../../actions/stripe.actions'
import {
  UPDATE_STRIPE_DEFAULT_PAYMENT_SOURCE,
  GET_STRIPE_DEFAULT_SOURCE,
  CANCEL_USER_STRIPE_SUBCRIPTION,
  PAUSE_USER_STRIPE_SUBSCRIPTION,
  UNDO_PAUSE_USER_STRIPE_SUBSCRIPTION,
  UNDO_CANCEL_USER_STRIPE_SUBCRIPTION
} from 'constants/routes'
import { get, post } from '../../api'

const basePath = '/api/basic'

export const getStripeApiKey = function* (action) {
  try {
    const response = yield call(get, `${basePath}/stripe`)
    const data = yield response.json()
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: ActionTypes.GET_STRIPE_API_KEY_SUCCESS,
        stripeApiKey: data.content.publishable,
      })
    } else {
      throw data
    }
  } catch (error) {
    yield put({
      type: ActionTypes.GET_STRIPE_API_KEY_FAILURE,
      errorMessage: '',
    })
  }
}

export const cancelUserStripeSubscription = function* (action) {
  try {
    const response = yield call(post, CANCEL_USER_STRIPE_SUBCRIPTION)
    const data = yield response.json()
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: ActionTypes.CANCEL_USER_STRIPE_SUBCRIPTION_SUCCESS
      })
    } else {
      throw data
    }
  } catch (error) {
    yield put({
      type: ActionTypes.CANCEL_USER_STRIPE_SUBCRIPTION_FAILURE,
      errorMessage: '',
    })
  }
}

export const pauseUserStripeSubscription = function* (action) {
  try {
    const response = yield call(post, PAUSE_USER_STRIPE_SUBSCRIPTION)
    const data = yield response.json()
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: ActionTypes.PAUSE_USER_STRIPE_SUBSCRIPTION_SUCCESS
      })
    } else {
      throw data
    }
  } catch (error) {
    yield put({
      type: ActionTypes.PAUSE_USER_STRIPE_SUBSCRIPTION_FAILURE,
      errorMessage: '',
    })
  }
}

export const undoPauseUserStripeSubscription = function* (action) {
  try {
    const response = yield call(post, UNDO_PAUSE_USER_STRIPE_SUBSCRIPTION)
    const data = yield response.json()
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: ActionTypes.UNDO_PAUSE_USER_STRIPE_SUBSCRIPTION_SUCCESS
      })
    } else {
      throw data
    }
  } catch (error) {
    yield put({
      type: ActionTypes.UNDO_PAUSE_USER_STRIPE_SUBSCRIPTION_FAILURE,
      errorMessage: '',
    })
  }
}

export const undoCancelUserStripeSUbscription = function* (action) {
  try {
    const response = yield call(post, UNDO_CANCEL_USER_STRIPE_SUBCRIPTION)
    const data = yield response.json()
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: ActionTypes.UNDO_CANCEL_USER_STRIPE_SUBCRIPTION_SUCCESS
      })
    } else {
      throw data
    }
  } catch (error) {
    yield put({
      type: ActionTypes.UNDO_CANCEL_USER_STRIPE_SUBCRIPTION_FAILURE,
      errorMessage: '',
    })
  }
}

function* stripe() {
  yield all([
    takeLatest(ActionTypes.GET_STRIPE_API_KEY, getStripeApiKey),
    takeLatest(ActionTypes.CANCEL_USER_STRIPE_SUBCRIPTION, cancelUserStripeSubscription),
    takeLatest(ActionTypes.PAUSE_USER_STRIPE_SUBSCRIPTION, pauseUserStripeSubscription),
    takeLatest(ActionTypes.UNDO_PAUSE_USER_STRIPE_SUBSCRIPTION, undoPauseUserStripeSubscription),
    takeLatest(ActionTypes.UNDO_CANCEL_USER_STRIPE_SUBCRIPTION, undoCancelUserStripeSUbscription)
  ])

}

export default stripe
