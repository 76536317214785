export const STUDIOS = "/studios";
export const HOW_IT_WORKS = "/how-it-works";
export const HEALTH_CARE = "/whoweserve/healthplans";
export const WORKPLACE_WELLNESS = "/whoweserve/employers";
export const EDUCATION = "/whoweserve/schools";
export const LIVE_SCHEDULE = "/liveschedule";
export const BLOG = "https://www.blog.jetsweatfitness.com/";
export const GEAR = "https://www.blog.jetsweatfitness.com/new-products/";
export const LOG_IN = "/login";
export const LOG_OUT = "/logout";
export const WHITE_LABEL = "https://www.jetsweat.io/";

export const HOME = "/";
export const PRIVACY_POLICY = "https://www.jetsweatfitness.com/privacypolicy";
export const TERMS_OF_SERVICE = "https://www.jetsweatfitness.com/terms";
export const FACEBOOK = "https://www.facebook.com/jetsweatfitness";
export const INSTAGRAM = "https://www.instagram.com/jetsweatfitness/";

export const APP_STORE = "https://apps.apple.com/us/app/jetsweat/id1262223853";

// Backend Routes
export const UPDATE_STRIPE_DEFAULT_PAYMENT_SOURCE = "/api/user/subscription/update-card";
export const GET_STRIPE_DEFAULT_SOURCE = "/api/user/subscription/payment-source/";
export const CANCEL_USER_STRIPE_SUBCRIPTION = "/api/user/subscription/cancel";
export const PAUSE_USER_STRIPE_SUBSCRIPTION = "/api/user/subscription/pause";
export const UNDO_PAUSE_USER_STRIPE_SUBSCRIPTION = "/api/user/subscription/un-pause";
export const UNDO_CANCEL_USER_STRIPE_SUBCRIPTION = "/api/user/subscription/un-cancel";