export const ActionTypes = {
  ADD_FRIEND: 'ADD_FRIEND',
  ADD_FRIEND_SUCCESS: 'ADD_FRIEND_SUCCESS',
  ADD_FRIEND_FAILURE: 'ADD_FRIEND_FAILURE',
  REMOVE_FRIEND: 'REMOVE_FRIEND',
  REMOVE_FRIEND_SUCCESS: 'REMOVE_FRIEND_SUCCESS',
  REMOVE_FRIEND_FAILURE: 'REMOVE_FRIEND_FAILURE',
  UPDATE_FRIEND_REQUEST: 'UPDATE_FRIEND_REQUEST',
  UPDATE_FRIEND_REQUEST_SUCCESS: 'UPDATE_FRIEND_REQUEST_SUCCESS',
  UPDATE_FRIEND_REQUEST_FAILURE: 'UPDATE_FRIEND_REQUEST_FAILURE',
  GET_FRIENDS: 'GET_FRIENDS',
  GET_FRIENDS_SUCCESS: 'GET_FRIENDS_SUCCESS',
  GET_FRIENDS_FAILURE: 'GET_FRIENDS_FAILURE',
  GET_FRIEND_REQUESTS: 'GET_FRIEND_REQUESTS',
  GET_FRIEND_REQUESTS_SUCCESS: 'GET_FRIEND_REQUESTS_SUCCESS',
  GET_FRIEND_REQUESTS_FAILURE: 'GET_FRIEND_REQUESTS_FAILURE',
  SEARCH_FRIENDS: 'SEARCH_FRIENDS',
  SEARCH_FRIENDS_SUCCESS: 'SEARCH_FRIENDS_SUCCESS',
  SEARCH_FRIENDS_FAILURE: 'SEARCH_FRIENDS_FAILURE',
  SEND_EMAIL_INVITE: 'SEND_EMAIL_INVITE',
  SEND_EMAIL_INVITE_SUCCESS: 'SEND_EMAIL_INVITE_SUCCESS',
  SEND_EMAIL_INVITE_FAILURE: 'SEND_EMAIL_INVITE_FAILURE',
  SEND_TEXT_INVITE: 'SEND_TEXT_INVITE',
  SEND_TEXT_INVITE_SUCCESS: 'SEND_TEXT_INVITE_SUCCESS',
  SEND_TEXT_INVITE_FAILURE: 'SEND_TEXT_INVITE_FAILURE',
  ACCEPT_FRIEND_REQUEST: 'ACCEPT_FRIEND_REQUEST',
  ACCEPT_FRIEND_REQUEST_SUCCESS: 'ACCEPT_FRIEND_REQUEST_SUCCESS',
  ACCEPT_FRIEND_REQUEST_FAILURE: 'ACCEPT_FRIEND_REQUEST_FAILURE',
  DECLINE_FRIEND_REQUEST: 'DECLINE_FRIEND_REQUEST',
  DECLINE_FRIEND_REQUEST_SUCCESS: 'DECLINE_FRIEND_REQUEST_SUCCESS',
  DECLINE_FRIEND_REQUEST_FAILURE: 'DECLINE_FRIEND_REQUEST_FAILURE',
  CLEAR_SEARCH: 'CLEAR_SEARCH',
}

export const getFriends = function(headers) {
  return {
    type: ActionTypes.GET_FRIENDS,
    payload: {
      headers
    },
  }
}

export const getFriendRequests = function(headers) {
  return {
    type: ActionTypes.GET_FRIEND_REQUESTS,
    payload: {
      headers
    },
  }
}

export const addFriend = function (friendUserId, headers) {
  return {
    type: ActionTypes.ADD_FRIEND,
    payload: {
      friendUserId,
      headers
    },
  }
}

export const updateFriendRequest= function (userFriendId, accepted, headers) {
  return {
    type: ActionTypes.UPDATE_FRIEND_REQUEST,
    payload: {
      userFriendId,
      accepted,
      headers
    },
  }
}

export const removeFriend = function (friendId, userFriendId, headers) {
  return {
    type: ActionTypes.REMOVE_FRIEND,
    payload: {
      friendId,
      userFriendId,
      headers
    },
  }
}

export const searchFriends = function (email, headers) {
  return {
    type: ActionTypes.SEARCH_FRIENDS,
    payload: {
      email,
      headers
    },
  }
}

export const sendEmailInvite = function (email) {
  return {
    type: ActionTypes.SEND_EMAIL_INVITE,
    payload: {
      email
    },
  }
}

export const sendTextInvite = function (phone) {
  return {
    type: ActionTypes.SEND_TEXT_INVITE,
    payload: {
      phone
    },
  }
}

export const acceptFriendRequest = function (inviteUserId) {
  return {
    type: ActionTypes.ACCEPT_FRIEND_REQUEST,
    payload: {
      inviteUserId
    },
  }
}

export const declineFriendRequest = function (inviteUserId) {
  return {
    type: ActionTypes.DECLINE_FRIEND_REQUEST,
    payload: {
      inviteUserId
    },
  }
}

export const clearSearch = function() {
  return {
    type: ActionTypes.CLEAR_SEARCH
  }
}
