import React from "react";
import { Link } from "react-router-dom";
import "./accountNew.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import appstore from "images/appstore.png";
import roku from "images/roku.png";
import ccast from "images/ccast.png";

const AccountNew = ({ location, currentUser }) => {
  return (
    <div className="account">
      <div className="account__box">
        <section className="account__box--1"></section>
        <section className="account__box--2">
          <div className="check__circle--box">
            <FontAwesomeIcon
              className="check__circle--icon"
              icon={faCheckCircle}
            />
          </div>
          <div className="account__header margin__top--2">
            <h1 className="account__header--text font--weight font--size-1">
              {currentUser.firstName}, Your Subscription is <br /> Confirmed!
            </h1>
          </div>
          <div className="margin__top--4 ">
            <h3 className="font--weight font--size-2">
              Check your inbox for a confirmation email.
            </h3>
            <h3 className="margin__top--3 font--weight font--size-2">
              Here’s how you can get started:
            </h3>
            <p className="margin__top--2 paragraph--size font--size-3">
              1) Continue to the{" "}
              <span>
                <Link className="link_color" to={'/newplatform/dashboard' + (window?.location?.search ?? '')}>
                  {" "}
                  web platform
                </Link>{" "}
              </span>
              or access JETSWEAT via one of the options below
            </p>
            <p className="margin__top--1 paragraph--size font--size-3">
              2) Complete your fitness profile
            </p>
            <p className="margin__top--1 paragraph--size font--size-3">
              3) Workout now or explore workouts to schedule for later
            </p>
          </div>
          <div className="stores margin__top--4">
            <h4 className="stores--text font--size-2">Also available on:</h4>
            <div className="stores--box  margin__top--2">
              <a
                href="https://apps.apple.com/us/app/jetsweat/id1262223853"
                target="_blank"
              >
                <img
                  className="stores--box--image"
                  src={appstore}
                  alt="app store logo"
                />
              </a>
              <a
                href="https://channelstore.roku.com/details/16f509e65f017eaed463030b99a47b50/jetsweat"
                target="_blank"
              >
                <img
                  className="stores--box--image"
                  src={roku}
                  alt=" roku store logo"
                />
              </a>
              <a href="https://jetsweatfitness.com/how-to-connect">
                <img
                  className="stores--box--image"
                  src={ccast}
                  alt="ccast store logo"
                />
              </a>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default AccountNew;
