import { ActionTypes } from "redux/actions/video.actions.js"

const initalState = {
    allPrograms: {},
    mapProgIdToTitle: {},
    error: {},
    recentlyAddedClasses: [],
    AllClassTypes: undefined
}

export default function videoReducer(state = initalState, action) {
    switch (action.type) {
        case ActionTypes.GET_PROGRAMS_SUCCESS:
            //const mapProgIdToTitle = {}
            const programs = [...action.programsInfo]
            const mapProgIdToTitle = programs.reduce((prevState, currState) => ({ ...prevState, [currState.id]: currState.title }), {})

            return {
                ...state,
                allPrograms: programs,
                mapProgIdToTitle: mapProgIdToTitle,
                error: {}
            }

        case ActionTypes.GET_PRORAMS_FAILURE:
            return {
                ...state,
                error: { ...action.error }
            }
        case ActionTypes.GET_ALL_CLASSES_SUCCESS:
            const AllClassTypes = [...new Set(action.classes.flatMap(classType => classType.classType))]
            return {
                ...state,
                classes: action.classes,
                recentlyAddedClasses: [...action.classes],
                AllClassTypes: AllClassTypes
            }
        case ActionTypes.GET_ALL_CLASSES_FAILURE:
            return {
                ...state,
                error: { ...action.error }
            }

        default:
            return state
    }
}