export const ActionTypes = {
  ADD_MESSAGE: 'ADD_MESSAGE',
  ADD_MESSAGE_SUCCESS: 'ADD_MESSAGE_SUCCESS',
  ADD_MESSAGE_FAILURE: 'ADD_MESSAGE_FAILURE',
  ADD_MESSAGES: 'ADD_MESSAGES',
  ADD_MESSAGES_SUCCESS: 'ADD_MESSAGES_SUCCESS',
  ADD_MESSAGES_FAILURE: 'ADD_MESSAGES_FAILURE',
  REFRESH_MESSAGES: 'REFRESH_MESSAGES',
  INITIALIZE_MESSAGES: 'INITIALIZE_MESSAGES'
}

export const addMessage = function (message) {
  return {
    type: ActionTypes.ADD_MESSAGE,
    payload: {
      message
    }
  }
}


export const addMessages = function (messages) {
  return {
    type: ActionTypes.ADD_MESSAGES,
    payload: {
      messages
    }
  }
}

export const initializeMessages = function (messages) {
  return {
    type: ActionTypes.INITIALIZE_MESSAGES,
    payload: {
      messages
    }
  }
}

export const refreshMessages = function (messages) {
  return {
    type: ActionTypes.REFRESH_MESSAGES,
    payload: {
      messages
    }
  }
}
