import { ActionTypes } from "redux/actions/antmedia.actions";

const initialState = {
    availableBroadcast: [],
    error: {}
}

const antmediaReducer = (state = initialState, action) => {
    switch (action.type) {

        case ActionTypes.CREATE_BROADCAST_SUCCESS:
            return {
                ...state,
                availableBroadcast: [...state.availableBroadcast, action.streamId]
            }
        case ActionTypes.CREATE_BROADCAST_FAILURE:
            let availableBroadcast = []
            if (action.error.message == "Stream id is already being used. Please change stream id or keep it empty") {
                availableBroadcast = [...state.availableBroadcast, action.streamId]
            }
            return {
                ...state,
                availableBroadcast: availableBroadcast,
                error: action.error,
            }
        case ActionTypes.DELETE_BROADCAST_SUCCESS:
            return {
                ...state,
                availableBroadcast: state.availableBroadcast.filter(br => br != action.streamId)
            }
        case ActionTypes.DELETE_BROADCAST_FAILURE:
            return {
                ...state,
                error: action.error
            }
        case ActionTypes.DELETE_THEN_CREATE_BROADCAST_SUCCESS:
            return {
                ...state
            }
        case ActionTypes.DELETE_THEN_CREATE_BROADCAST_FAILURE:
            return {
                ...state,
                error: action.error
            }
        default:
            return state;
    }


}

export default antmediaReducer;