import { getAllProfileData } from '../../actions';
import { calculateUserAge } from '../../utilities';

const initialState = {};

export const fetchProfileData = function (userData) {
  return async (dispatch) => {
    const response = await fetch('/api/user/me', {
      method: 'GET',
      headers: {
        'Content-type': 'application/json',
        Authorization: userData.authToken,
      },
    });
    const data = await response.json();

    const profileInfo = data.content;
    const userAge = calculateUserAge(profileInfo.birthday);

    const action = getAllProfileData(profileInfo, userAge);
    dispatch(action);
  };
};

export default function profileReducer(state = initialState, action) {
  switch (action.type) {
    case 'GET_ALL_PROFILE_DATA':
      return {
        ...state,
        userAge: action.userAge || 35,
        userWeight: action.profileData.weight || 143,
        userGender: action.profileData.gender || 'F'
      };
    default:
      return state;
  }
}
